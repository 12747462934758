import React, { useState } from "react";

const FreeDemoForm = () => {
  const [values, setValues] = useState({
    fullName: "",
    email: "",
    subject: "",
    textarea: "",
    HyDDEP: false,
    AIMLoW: false,
    NERoML: false,
    industry: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [valid, setValid] = useState(false);

  const handleFullNameInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      fullName: event.target.value,
    }));
  };

  const handleEmailInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      email: event.target.value,
    }));
  };

  const handleSubjectInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      subject: event.target.value,
    }));
  };

  const handleTextAreaInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      textarea: event.target.value,
    }));
  };

  const productHyDDEPHandleChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      HyDDEP: !values.HyDDEP,
    }));
  };

  const productAIMLoWHandleChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      AIMLoW: !values.AIMLoW,
    }));
  };
  const productNERoMLHandleChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      NERoML: !values.NERoML,
    }));
  };

  const industryHandleChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      industry: event.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.fullName && values.email && values.textarea) {
      setValid(true);
    }
    setSubmitted(true);
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <h2 className="form-text-top">Request a Free Demo</h2>
        <p className="form-text">
          Please complete the form below, to request a free demo, and we'll be
          in touch.
        </p>
      </div>
      <form class="free-demo-form" onSubmit={handleSubmit}>
        <div class="inline-form">
          <div class="row-select">
            <div className="input-row-form">
              <label id="fullname" className="text-font">Full Name:</label>
              <input
                id="fullname"
                class="demo-form-field"
                type="text"
                placeholder=""
                name="fullname"
                value={values.fullName}
                onChange={handleFullNameInputChange}
              />
              {submitted && !values.fullName && (
                <span className="form-errors" id="full-name-error">
                  Please enter a full name
                </span>
              )}
            </div>
          </div>
          <div class="row-select">
            <div className="input-row-form">
                <label className="text-font">Work Email:</label>
                <input
                  id="email"
                  class="demo-form-field"
                  type="text"
                  placeholder=""
                  name="email"
                  value={values.email}
                  onChange={handleEmailInputChange}
                />
                {submitted && !values.email && (
                  <span className="form-errors" id="email-error">
                    Please enter an email address
                  </span>
                )}
            </div>
          </div>
        </div>
        <div class="inline-select">
          <div class="row-select">
            <div className="select-row-form">
              <label className="text-font">Select your industry:</label>
              <select
                class="select-form-field"
                value={values.industry}
                onChange={industryHandleChange}
              >
                <option class="option" value="finance">
                  Financial Services
                </option>
                <option class="option" value="government">
                  Government
                </option>
                <option class="option" value="retail">
                  Retail
                </option>
                <option class="option" value="telecommunication">
                  Telecommunication
                </option>
              </select>
            </div>
          </div>
          <div class="row-checkbox">
            <div className="select-row-form">
              <label className="checkbox-text-font">Select your product:</label>
              <div className="select-form-checkbox">
                <input
                  type="checkbox"
                  name="HyDDEP"
                  value="HyDDEP"
                  checked={values.HyDDEP}
                  onChange={productHyDDEPHandleChange}
                />
                <label className="checkbox-font">HyDDEP</label>
              </div>
              <div className="select-form-checkbox">
                <input
                  type="checkbox"
                  name="AIMLow"
                  value="AIMLow"
                  checked={values.AIMLoW}
                  onChange={productAIMLoWHandleChange}
                />
                <label className="checkbox-font">AIMLow</label>
              </div>
              <div className="select-form-checkbox">
                <input
                  type="checkbox"
                  name="NERoML"
                  value="NERoML"
                  checked={values.NERoML}
                  onChange={productNERoMLHandleChange}
                />
                <label className="checkbox-font">NERoML</label>
              </div>
            </div>
          </div>
        </div>

        <div className="sales-row-form">
          <label className="text-font">Subject:</label>
          <input
            id="subject"
            class="form-field"
            type="text"
            placeholder=""
            name="subject"
            value={values.subject}
            onChange={handleSubjectInputChange}
          />
        </div>

        <div className="sales-row-form">
          <label className="text-font">How can we help?</label>
          <textarea
            id="teaxtarea"
            class="form-area"
            type="textarea"
            placeholder=""
            name="textarea"
            value={values.textarea}
            onChange={handleTextAreaInputChange}
            rows="5"
            required
          />
        </div>
        <button class="form-button" type="submit">
          Get Free Demo
        </button>
        {submitted && (
          <div class="success-message">
            Your message has been sent. Thank you!
          </div>
        )}
      </form>
    </div>
  );
};

export default FreeDemoForm;
