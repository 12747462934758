import React from "react";
import Navigation from "../components/home-page/NavigationSection";
import TelecommunicationProductPage from "../components/industries/telecommunication/TelecomProductPage";
import MinimalFooter from "../components/footer-section/MinimalFooter";

const TelecommunicationProducts = () => {
  return (
    <div>
      <Navigation />
      <TelecommunicationProductPage />
      <MinimalFooter />
    </div>
  );
};

export default TelecommunicationProducts;
