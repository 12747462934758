import React from "react";
import MinimalFooter from "./components/footer-section/MinimalFooter";
import Navigation from "./components/home-page/NavigationSection";
import PrivacyPolicy from "./components/PrivacyPolicy";

const PrivacyPolicyPage = () => {
  return (
    <div>
        <Navigation />
        <PrivacyPolicy />
        <MinimalFooter />
    </div>
  )
}

export default PrivacyPolicyPage;