export const keyAreaData = [
  {
    title: 'Transactions',
    keyIssues: ['Poor predictive capabilities', 'Disparate and siloed data', 'Cost instability', 'Sub-optimal pricing', 'Uncertainty in demand and supply', ]
  },
  {
    title: 'Commercial',
    keyIssues: ['Imprecise forecasts', 'Expensive customer churn', 'Missed up-sell and x-sell opportunities', 'Complex rate and fee structure', 'Fragmented view of customer profitability', ]
  },
  {
    title: 'Products',
    keyIssues: ['Lengthy processes', 'Slow decision-making', 'Infrequent client touch-points', 'Siloed customer information', 'One-size-fits-all client experience', ]
  },
  {
    title: 'Middle/Back Office',
    keyIssues: ['High cost of regulatory compliance', 'Outdated rules-based systems', 'Overly manual processes', 'Siloed data', 'Poor responsiveness', ]
  },
]

export const keyAreaProducts = [
  {
    title: 'Key Capabilities',
    keyIssues: ['Dashboard Module Analytics', 'Data Management and Exploration', 'Workflow Management toolkits', 'Anomaly Management toolkits', 'Reporting', 'Entity Resolution']
  },
  {
    title: 'Use Cases',
    keyIssues: ['AML', 'Customer 360', 'Fraud Protection', 'Process Automation', 'Risk Management', 'Credit risk assessment and management ']
  },
]