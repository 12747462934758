import React from "react";

const TermsOfUse = () => {
  return (
    <main id="main">
      <div className="container">
        <section>
          <div className="container-margin-sides">
            <h1 className="title-head" data-aos="fade-down">Terms of Use</h1>
            <p className="terms-and-policy-text" data-aos="fade-up">
              These Terms Of Use (THESE "TERMS") set forth the legally binding
              terms and conditions between Dark Pools (Pty) Ltd . ("Dark Pools",
              "we", "our," and "us") and you that govern your use of the website
              located at www.darkpools.ai and other websites that link to these
              Terms (collectively, the "Site"). Certain features of the Site may
              be subject to additional guidelines, terms, or rules, which will
              be posted on the Site in connection with such features. All such
              additional terms, guidelines, and rules are incorporated by
              reference into these Terms. Access and use of the Dark Pools
              Solutions Platform and Dark Pools Products are governed by a
              Master Subscription and Services Agreement, or other applicable
              agreement, between you and Dark Pools, and not these Terms. By
              accessing or using the site, you are accepting these terms (on
              behalf of yourself or the entity that you represent), and you
              represent and warrant that you have the right, authority, and
              capacity to enter into these terms (on behalf of yourself or the
              entity that you represent). You may not access or use the site or
              accept the terms if you are not at least 18 years old. If you do
              not agree with all of the provisions of these terms, do not access
              and/or use the site. Dark Pools reserves the right to update the
              terms at any time without notice to you. Continued use of our Site
              following any change to the terms indicates your acknowledgement
              of such changes and agreement to be bound by modified terms and
              conditions.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">
              <strong>1. Accounts</strong>
            </h2>
            <p className="sub-main-title" data-aos="fade-up">
              <strong>1.1 ACCOUNT CREATION</strong>
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              In order to use certain features of the Site, you must register
              for an account ("Account") and provide certain information about
              yourself as prompted by the account registration form. You
              represent and warrant that: (a) all required registration
              information you submit is current, complete, truthful and
              accurate; and (b) you will maintain the accuracy of such
              information. You may delete your Account at any time, for any
              reason, by sending an email to us at privacy@darkpools.co.za and
              telling us, with sufficient detail, that you wish to delete your
              Account with us. Dark Pools may suspend or terminate your Account
              in accordance with respective Section's.
            </p>
            <p className="sub-main-title" data-aos="fade-up">
              <strong>1.2 ACCOUNT RESPONSIBILITIES</strong>
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              You are responsible for maintaining the confidentiality of your
              Account login information and are fully responsible for all
              activities that occur under your Account. Dark Pools is not liable
              for any loss that you may incur as a result of someone else using
              your password or account, either with or without your knowledge.
              You may not share your account with anyone else. You agree to
              immediately notify Dark Pools of any unauthorised use, or
              suspected unauthorised use of your Account or any other breach of
              security. Dark Pools cannot and will not be liable for any loss or
              damage arising from your failure to comply with the above
              requirements.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">
              <strong>2. Access to the site</strong>
            </h2>
            <p className="sub-main-title" data-aos="fade-up">
              <strong>2.1 LICENSE</strong>
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              Subject to these Terms, Dark Pools grants you a non-transferable,
              non-exclusive, revocable, limited license to use and access the
              Site solely for your own personal, non-commercial use.
            </p>
            <p className="sub-main-title" data-aos="fade-up">2.2 CERTAIN RESTRICTIONS</p>
            <p className="main-content-paragraph" data-aos="fade-up">
              The rights granted to you in these Terms are subject to the
              following restrictions: (a) you shall not license, sell, rent,
              lease, transfer, assign, distribute, host, or otherwise
              commercially exploit the Site, whether in whole or in part, or any
              content displayed on the Site; (b) you shall not modify, make
              derivative works of, disassemble, reverse compile or reverse
              engineer any part of the Site; (c) you shall not access the Site
              in order to build a similar or competitive website, product, or
              service; and (d) except as expressly stated herein, no part of the
              Site may be copied, reproduced, distributed, republished,
              downloaded, displayed, posted or transmitted in any form or by any
              means. You may not frame or utilise framing techniques to enclose
              any trademark, logo, or other proprietary information of Dark
              Pools without the express written consent of Dark Pools. Unless
              otherwise indicated, any future release, update, or other addition
              to functionality of the Site shall be subject to these Terms. All
              copyright and other proprietary notices on the Site (or on any
              content displayed on the Site) must be retained on all copies
              thereof.
            </p>
            <p className="sub-main-title" data-aos="fade-up">2.3 MODIFICATION</p>
            <p className="main-content-paragraph" data-aos="fade-up">
              Dark Pools reserves the right, at any time, to modify, suspend, or
              discontinue the Site (in whole or in part) with or without notice
              to you. You agree that Dark Pools will not be liable to you or
              to any third party for any modification, suspension, or
              discontinuation of the Site or any part thereof.
            </p>
            <p className="sub-main-title" data-aos="fade-up">2.4 NO SUPPORT OR MAINTENANCE</p>
            <p className="main-content-paragraph" data-aos="fade-up">
              You acknowledge and agree that Dark Pools will have no
              obligation to provide you with any support or maintenance in
              connection with the Site.
            </p>
            <p className="sub-main-title" data-aos="fade-up">2.5 OWNERSHIP</p>
            <p className="main-content-paragraph" data-aos="fade-up">
              Excluding any User Content that you may provide (defined in
              Section 3.1 below), you acknowledge that all the intellectual
              property rights, including copyrights, patents, trademarks, and
              trade secrets, in the Site and its content are owned by Dark Pools
             or Dark Pools suppliers. Neither these Terms nor your
              access to the Site transfers to you or any third party any rights,
              title or interest in or to such intellectual property rights,
              except for the limited access rights expressly set forth in
              Section 2.1. Dark Pools and its suppliers reserve all rights not
              granted in these Terms. There are no implied licenses granted
              under these Terms.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">3. User Content</h2>
            <p className="sub-main-title" data-aos="fade-up">3.1 USER CONTENT</p>
            <p className="main-content-paragraph" data-aos="fade-up">
              "User Content" means any and all information, data and content
              that a user uploads, posts, inputs, or submits to, or uses with,
              the Site (e.g., content in the user's profile or postings). You
              are solely responsible for your User Content. You assume all risks
              associated with use of your User Content, including any reliance
              on its accuracy, completeness or usefulness by others, or any
              disclosure of your User Content that personally identifies you or
              any third party. You hereby represent and warrant that your User
              Content does not violate our Acceptable Use Policy (defined in
              Section 3.3) and you are liable for any damages arising from a
              violation of the Acceptable Use Policy. Notwithstanding any other
              agreement you may have with Dark Pools, your User Content is not
              confidential information and will not be treated as such by
              Dark Pools. You may not represent or imply to others that your
              User Content is in any way provided, sponsored or endorsed by
              Dark Pools. Dark Pools is not obligated to backup any User
              Content, and your User Content may be deleted at any time without
              prior notice. You are solely responsible for creating and
              maintaining your own backup copies of your User Content if you
              desire. The Dark Pools Privacy Policy at
              https://DarkPools.ai/privacy-policy/ governs any personal
              information that you provide us.
            </p>
            <p className="sub-main-title" data-aos="fade-up">3.2 LICENSE</p>
            <p className="main-content-paragraph" data-aos="fade-up">
              You hereby grant (and you represent and warrant that you have the
              right to grant) to Dark Pools and its affiliates an irrevocable,
              perpetual, nonexclusive, transferable, royalty-free and fully
              paid, worldwide, unrestricted license to encode, store, transmit,
              publish, post, broadcast, adapt, modify, exhibit, commercialise,
              make, sell, reproduce, distribute, publicly display and perform,
              prepare derivative works of, incorporate into other works, and
              otherwise use and exploit your User Content, and to grant
              sublicenses of the foregoing rights, for any purpose whatsoever
              without attribution, accounting or compensation to you or your
              company. You hereby irrevocably waive (and agree to cause to be
              waived) any claims and assertions of moral rights or attribution
              with respect to your User Content. You hereby grant us the right
              to publish your name in connection with your User Content. By
              posting User Content you represent and warrant that you own or
              otherwise control all of the rights to your User Content,
              including the rights necessary for you to provide, post, upload,
              input or submit the User Content and grant the license above to
              such User Content. The license set forth in this Section 3.2 shall
              be subject to the Dark Pools Privacy Policy with respect to any
              personal information you provide us.
            </p>
            <p className="sub-main-title" data-aos="fade-up">3.3 ACCEPTABLE USE POLICY</p>
            <p className="main-content-paragraph" data-aos="fade-up">
              The following terms constitute our "Acceptable Use Policy":
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              a. You agree not to use the Site to collect, upload, transmit,
              display, or distribute any User Content: (i) that violates any
              third-party right, including any copyright, trademark, patent,
              trade secret, moral right, privacy right, right of publicity, or
              any other intellectual property or proprietary right; (ii) that is
              unlawful, harassing, abusive, tortious, threatening, harmful,
              invasive of another's privacy, vulgar, defamatory, false,
              misleading, trade libelous, pornographic, obscene, patently
              offensive, promotes racism, bigotry, hatred, or physical harm of
              any kind against any group or individual or is otherwise
              objectionable; (iii) that is harmful to minors in any way; or (iv)
              that is in violation of any law, regulation, or obligations or
              restrictions imposed by any third party.
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              b. You agree not to: (i) upload, transmit, or distribute to or
              through the Site any computer viruses, worms, Trojan horses, time
              bombs, cancelbots, corrupted files, or any software intended to
              damage or alter a computer system or data; (ii) send through the
              Site unsolicited or unauthorised advertising, promotional
              materials, junk mail, spam, political campaigning, chain letters,
              pyramid schemes, or any other form of duplicative or unsolicited
              messages, whether commercial or otherwise; (iii) use the Site to
              harvest, collect, gather or assemble information or data regarding
              other users, including e-mail addresses, without their consent;
              (iv) interfere with, disrupt, or create an undue burden on servers
              or networks connected to the Site, or violate the regulations,
              policies or procedures of such networks; (v) attempt to gain
              unauthorised access to the Site (or to other computer systems or
              networks connected to or used together with the Site), whether
              through password mining or any other means; (vi) harass or
              interfere with any other user's use and enjoyment of the Site;
              (vii) use a false e-mail address, impersonate any person or
              entity, or otherwise mislead as to the origin of User Content; or
              (viii) use software or automated agents or scripts to produce
              multiple accounts on the Site, or to generate automated searches,
              requests, or queries to (or to strip, scrape, or mine data from)
              the Site (provided, however, that we conditionally grant to the
              operators of public search engines revocable permission to use
              spiders to copy materials from the Site for the sole purpose of
              and solely to the extent necessary for creating publicly available
              searchable indices of the materials, but not caches or archives of
              such materials, subject to the parameters set forth in our
              robots.txt file).
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">
              4. Third-Party Links & Ads; Other Users
            </h2>
            <p className="sub-main-title" data-aos="fade-up">4.1 THIRD-PARTY LINKS & ADS</p>
            <p className="main-content-paragraph" data-aos="fade-up">
              The Site may contain links to third-party websites and services,
              and/or display advertisements for third parties (collectively,
              "Third-Party Links & Ads"). Such Third-Party Links & Ads are not
              under the control of Dark Pools, and Dark Pools is not responsible
              for any Third-Party Links & Ads. Dark Pools provides access to
              these Third-Party Links & Ads only as a convenience to you, and
              does not review, approve, monitor, endorse, warrant, or make any
              representations with respect to Third-Party Links & Ads. Dark
              Pools does not represent or warrant the accuracy or reliability of
              any advice, opinion, statement, or other information displayed or
              distributed through any Third-Party Links & Ads. You use all
              Third-Party Links & Ads at your own risk, and should apply a
              suitable level of caution and discretion in doing so. When you
              click on any of the Third-Party Links & Ads, the applicable third
              party's terms and policies apply, including the third party's
              privacy and data gathering practices. You should make whatever
              investigation you feel necessary or appropriate before proceeding
              with any transaction in connection with such Third-Party Links &
              Ads.
            </p>
            <p className="sub-main-title" data-aos="fade-up">4.2 OTHER USERS</p>
            <p className="main-content-paragraph" data-aos="fade-up">
              Each Site user is solely responsible for any and all of its own
              User Content. Because we do not control User Content, you
              acknowledge and agree that we are not responsible for any User
              Content, whether provided by you or by others. We make no
              guarantees regarding the accuracy, currency, suitability, or
              quality of any User Content and Dark Pools does not endorse any
              User Content. Your interactions with other Site users are solely
              between you and such users. You agree that Dark Pools will not be
              responsible for any loss or damage incurred as the result of any
              such interactions or your use of any User Content. If there is a
              dispute between you and any Site user, we are under no obligation
              to become involved.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">5. Indemnification</h2>
            <p className="main-content-paragraph" data-aos="fade-up">
              You agree to indemnify and hold Dark Pools (and its officers,
              employees, and agents) harmless, including costs and attorneys'
              fees, from any claim or demand made by any third party due to or
              arising out of (a) your use of the Site, (b) your User Content,
              (c) your violation of these Terms; or (d) your violation of
              applicable laws or regulations. Dark Pools reserves the right, at
              your expense, to assume the exclusive defense and control of any
              matter for which you are required to indemnify us, and you agree
              to cooperate with our defense of these claims. You agree not to
              settle any matter without the prior written consent of Dark Pools.
              Dark Pools will use reasonable efforts to notify you of any such
              claim, action or proceeding upon becoming aware of it.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">6. Release</h2>
            <p className="main-content-paragraph" data-aos="fade-up">
              You hereby release and forever discharge Dark Pools (and our
              officers, employees, agents, successors, and assigns) from, and
              hereby waive and relinquish, each and every past, present, and
              future dispute, claim, controversy, demand, right, obligation,
              liability, action, and cause of action of every kind and nature
              (including personal injuries, death, and property damage), that
              has arisen or arises directly or indirectly out of, or that
              relates directly or indirectly to, the Site (including any
              interactions with, or act or omission of, other Site users or any
              Third-Party Links & Ads).
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">7. Disclaimers</h2>
            <p className="main-content-paragraph" data-aos="fade-up">
              THE SITE, INCLUDING ALL CONTENT, SOFTWARE, PRODUCTS, SERVICES,
              MATERIALS, AND INFORMATION MADE AVAILABLE ON OR ACCESSED THROUGH
              THE SITE, IS PROVIDED ON AN "AS-IS" AND "AS AVAILABLE" BASIS, AND,
              TO THE MAXIMUM EXTENT PERMITTED BY LAW, Dark Pools AND OUR
              SUPPLIERS EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS
              OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL
              WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR
              NON-INFRINGEMENT. NEITHER WE NOR OUR SUPPLIERS MAKE ANY WARRANTY
              THAT THE SITE, INCLUDING ALL CONTENT, SOFTWARE, PRODUCTS,
              SERVICES, MATERIALS, AND INFORMATION MADE AVAILABLE ON OR ACCESSED
              THROUGH THE SITE, WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE
              ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL
              BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE,
              COMPLETE, LEGAL, OR SAFE. IF APPLICABLE LAW REQUIRES ANY
              WARRANTIES WITH RESPECT TO THE SITE, ALL SUCH WARRANTIES ARE
              LIMITED IN DURATION TO NINETY (90) DAYS FROM THE DATE OF FIRST
              USE.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">8. Limitation On Liability</h2>
            <p className="main-content-paragraph" data-aos="fade-up">
              TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL DARK
              POOLS OR OUR SUPPLIERS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
              LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT OF SUBSTITUTE
              PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
              SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO YOUR USE
              OF, OR INABILITY TO USE, THE SITE, INCLUDING ANY CONTENT,
              SOFTWARE, PRODUCTS, SERVICES, MATERIALS, AND INFORMATION INCLUDED
              ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SITE, EVEN IF
              DARK POOLS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              ACCESS TO, AND USE OF, THE SITE IS AT YOUR OWN DISCRETION AND
              RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
              DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM. TO
              THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO
              THE CONTRARY CONTAINED HEREIN, OUR CUMULATIVE LIABILITY TO YOU FOR
              ANY DAMAGES ARISING FROM OR RELATED TO THE SITE, INCLUDING ANY
              CONTENT, SOFTWARE, PRODUCTS, SERVICES, MATERIALS, AND INFORMATION
              INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SITE
              (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE
              ACTION), WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OF TWO THOUSAND
              RAND (ZAR R2000.00E). THE EXISTENCE OF MORE THAN ONE CLAIM WILL
              NOT ENLARGE THIS LIMIT. YOU AGREE THAT OUR SUPPLIERS WILL HAVE NO
              LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THE SITE,
              INCLUDING ANY CONTENT, SOFTWARE, PRODUCTS, SERVICES, MATERIALS,
              AND INFORMATION INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU
              THROUGH THE SITE.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">9. Terms and Termination</h2>
            <p className="main-content-paragraph" data-aos="fade-up">
              Subject to this Section, these Terms will remain in full force and
              effect while you use the Site. We may refuse service, remove or
              edit content, including User Content, or suspend or terminate your
              rights to use the Site (including your Account) at any time for
              any reason at our sole discretion, including for any use of the
              Site in violation of these Terms. Upon termination of your rights
              under these Terms, your Account and right to access and use the
              Site will terminate immediately. You understand that any
              termination of your Account may involve deletion of your User
              Content associated with your Account from our databases. Dark
              Pools will not have any liability whatsoever to you for any
              termination of your rights under these Terms, including for
              termination of your Account or deletion of your User Content. Even
              after your rights under these Terms are terminated, the following
              provisions of these Terms will remain in effect: Sections 110 and
              Sections 114(4).
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">10. Enforcement</h2>
            <p className="main-content-paragraph" data-aos="fade-up">
              We reserve the right (but have no obligation) to review any User
              Content, and to investigate any violation of these Terms, and/or
              take appropriate remedial action against you in our sole
              discretion if you violate the Acceptable Use Policy or any other
              provision of these Terms or otherwise create liability for us or
              any other person. Such action may include removing or modifying
              your User Content, terminating your Account in accordance with
              Section 10, and/or reporting you to law enforcement authorities.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">11. CopyRight Policy</h2>
            <p className="main-content-paragraph" data-aos="fade-up">
              Dark Pools respects the intellectual property of others and asks
              that users of our Site do the same. In connection with our Site,
              we have adopted and implemented a policy respecting copyright law
              that provides for the removal of any infringing materials and for
              the termination, in appropriate circumstances, of users of our
              online Site who are infringers of intellectual property rights,
              including copyrights. If you believe that one of our users is,
              through the use of our Site, unlawfully infringing the
              copyright(s) in a work, and wish to have the allegedly infringing
              material removed, the following information in the form of a
              written notification must be provided to our designated Copyright
              Agent:
            </p>
            <ol data-aos="fade-up">
              <li className="main-content-paragraph">
                your physical or electronic signature;
              </li>
              <li className="main-content-paragraph">
                identification of the copyrighted work(s) that you claim to
                have been infringed;
              </li>
              <li className="main-content-paragraph">
                identification of the material on our services that you claim
                is infringing and that you request us to remove;
              </li>
              <li className="main-content-paragraph">
                sufficient information to permit us to locate such material;
              </li>
              <li className="main-content-paragraph">
                your address, telephone number, and e-mail address;
              </li>
              <li className="main-content-paragraph">
                a statement that you have a good faith belief that use of the
                objectionable material is not authorised by the copyright owner,
                its agent, or under the law; and
              </li>
              <li className="main-content-paragraph">
                7. a statement that the information in the notification is
                accurate, and under penalty of perjury, that you are either the
                owner of the copyright that has allegedly been infringed or that
                you are authorised to act on behalf of the copyright owner.
                Please note that any misrepresentation of material fact
                (falsities) in a written notification automatically subjects the
                complaining party to liability for any damages, costs and
                attorney's fees incurred by us in connection with the written
                notification and allegation of copyright infringement.
              </li>
            </ol>
            <p className="main-content-paragraph" data-aos="fade-up">
              The designated Copyright Agent for Dark Pools is:
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              Designated Agent: General Counsel
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              Address of Agent: 6 North Road, Dunkeld West. Johannesburg 2196
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">Telephone: +27 67 321 377</p>
            <p className="main-content-paragraph" data-aos="fade-up">Email: legal@darkpools.ai</p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">12. FEEDBACK</h2>
            <p className="main-content-paragraph" data-aos="fade-up">
              If you provide Dark Pools with any feedback or suggestions
              regarding the Site or any content on the Site ("Feedback"), you
              hereby assign to Dark Pools all rights in such Feedback and agree
              that Dark Pools shall have the right to use and fully exploit such
              Feedback and related information in any manner it deems
              appropriate without attribution, accounting, or compensation to
              you. Dark Pools will treat any Feedback you provide to Dark Pools
              as non-confidential and non-proprietary. You agree that you will
              not submit to Dark Pools any information or ideas that you
              consider to be confidential or proprietary.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">13. GENERAL</h2>
            <p className="sub-main-title" data-aos="fade-up">
              13.1 GOVERNING LAW AND DISPUTE RESOLUTION
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              a. You agree that all matters relating to your access and use of
              the Site, including all disputes, will be governed by the laws of
              South Africa, without regard to its conflict of law's provisions.
              The United Nations Convention on Contracts for the International
              Sale of Goods and the Uniform Computer Transactions Act are
              specifically excluded from application to this Agreement. b.
              Notwithstanding the foregoing, in the event of your or other's
              unauthorised access to or use of the Site or its content in
              violation of these Terms, you agree that we are entitled to apply
              for injunctive remedies (or an equivalent type of urgent legal
              relief) in any jurisdiction.
            </p>

            <p className="sub-main-title" data-aos="fade-up">13.2 ELECTRONIC COMMUNICATIONS</p>
            <p className="main-content-paragraph" data-aos="fade-up">
              The communications between you and Dark Pools use electronic
              means, whether you use the Site or send us emails, or whether Dark
              Pools posts notices on the Site or communicates with you via
              email. For contractual purposes, you (a) consent to receive
              communications from Dark Pools in an electronic form; and (b)
              agree that all terms and conditions, agreements, notices,
              disclosures, and other communications that Dark Pools provides to
              you electronically satisfy any legal requirement that such
              communications would satisfy if it were be in a hardcopy writing.
              The foregoing does not affect your non-waivable rights.
            </p>
            <p className="sub-main-title" data-aos="fade-up">13.3 ENTIRE TERMS</p>
            <p className="main-content-paragraph" data-aos="fade-up">
              These Terms constitute the entire agreement between you and us
              regarding the use of the Site, including all content, software,
              products, services, materials, and information made available on
              or accessed through the site. Our failure to exercise or enforce
              any right or provision of these Terms shall not operate as a
              waiver of such right or provision. The section titles in these
              Terms are for convenience only and have no legal or contractual
              effect. The word "including" means "including without limitation."
              If any provision of these Terms is, for any reason, held to be
              invalid, unenforceable, or illegal, the other provisions of these
              Terms will be unimpaired and the invalid or unenforceable
              provision will be deemed modified so that it is valid and
              enforceable to the maximum extent permitted by law. These Terms,
              and your rights and obligations herein, may not be assigned,
              subcontracted, delegated, or otherwise transferred by you without
              Dark Pools prior written consent, and any attempted assignment,
              subcontract, delegation, or transfer in violation of the foregoing
              will be null and void. Dark Pools may freely assign these Terms.
              The terms and conditions set forth in these Terms shall be binding
              upon assignees.
            </p>
            <p className="sub-main-title" data-aos="fade-up">
              13.4 COPYRIGHT/TRADEMARK INFORMATION
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              Copyright © 2023, Dark Pools (Pty) Ltd. All rights reserved. All
              trademarks, logos and service marks ("Marks") displayed on the
              Site are our property or the property of other third parties. The
              Dark Pools (Pty) Ltd. registered trademarks or other trademarks in
              South Africa and/or other countries include: Dark Pools (Pty) Ltd , Dark
              Pools. You are not permitted to use these Marks without our prior
              written consent or the consent of such third party which may own
              the Marks.
            </p>
            <p className="sub-main-title" data-aos="fade-up">13.5 CONTACT INFORMATION</p>
            <p className="main-content-paragraph" data-aos="fade-up">
              Dark Pools (Pty) Ltd, 6 North Road, Dunkeld West, Johannesburg.
              South Africa 2196,
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">Marcht 15, 2023</p>
          </div>
        </section>
      </div>
    </main>
  );
};

export default TermsOfUse;
