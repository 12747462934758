import Data_Source from "../assets/images/home/Data-Source.png";
import Data_Mapping from "../assets/images/home/Data-Mapping.png";
import Machine_Learning from "../assets/images/home/Machine-Learning.png";
import Data_Analytics from "../assets/images/home/Data-Analytics.png";
import Data_Visual from "../assets/images/home/Data-Visual.png";

export const data = [
  {
    title: "Data Source",
    icon: Data_Source,
    description:
      "Unify multiple dispersed and voluminous data sources for a centralised ELT data implementation",
    keyIssues: [
      "Integrate disparate data sources",
      "Create automated data pipelines",
      "Make sense of structured and unstructured data from both internal and external data sources (Not sure if this bullet point should be at Data Mapping)",
    ],
  },
  {
    title: "Data Mapping",
    icon: Data_Mapping,
    description:
      "Custom data homogenisation for better data management to be able to ingest relevant data for quality data processing to yield better business analytics and insights",
    keyIssues: [
      "Data mediation between data sources and destination",
      "Consolidation and creation of data element mappings",
      "No-Code data ingestion and filtering",
      "Data lineage that meets data normalisation standards",
    ],
  },
  {
    title: "Machine Learning",
    icon: Machine_Learning,
    description:
      "Next generation graph implemented machine learning algorithms for business intelligence and fraud detection",
    keyIssues: [
      "Seamlessly feed data to multiple algorithms",
      "Adjustable priority weights for using multiple algorithms in a single use case",
      "Obtain an increased accuracy with a low false positive result",
    ],
  },
  {
    title: "Data Analytics",
    icon: Data_Analytics,
    description:
      "Graph network with applied graph algorithms and machine learning that provides a full 360 degree transparency and insights into the business analytics",
    keyIssues: [
      "Link analysis allows for complete drill down into data",
      "Sophisticated multi-lever multi-actor orchestrated",
      "Discover semantic patterns and relationships",
      "Visual exploration and results",
    ],
  },
  {
    title: "Data Visual",
    icon: Data_Visual,
    description:
      "Integrated ecosystem of services and technologies showcased by executive dashboards that allows for drill down capabilities into the processed data",
    keyIssues: [
      "Generate alerts on insights obtained from the AI Suite",
      "Real-time and continuous monitoring with embedded triggers and case management feedback",
      "Visualisation Factory that allows for custom dashboards for different users",
    ],
  },
];

export const keyAreaProducts = [
  {
    title: "Key Capabilities",
    keyIssues: [
      "Dashboard Module Analytics",
      "Data Management and Exploration",
      "Workflow Management toolkits",
      "Anomaly Management toolkits",
      "Reporting",
      "Entity Resolution",
    ],
  },
  {
    title: "Use Cases",
    keyIssues: [
      "AML",
      "Customer 360",
      "Fraud Protection",
      "Process Automation",
      "Risk Management",
      "Credit risk assessment and management ",
    ],
  },
];
