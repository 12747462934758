import React from "react";
import Navigation from "../components/home-page/NavigationSection";
import AimlowPage from "../components/products/aimlow-page/AimlowPage";
import MinimalFooter from "../components/footer-section/MinimalFooter";

const AIMLow = () => {
  return (
    <div>
      <Navigation />
      <AimlowPage />
      <MinimalFooter />
    </div>
  );
};

export default AIMLow;
