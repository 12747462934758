import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import FreeDemoForm from "../FreeDemoForm";
import "../styles.css";

const NERoML = () => {
  return (
    <div id="full-form">
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <Link to="/HYDDeP" className="list-item sidebar-link">
            Overview
          </Link>
          <Link to="/HYDDeP-products" className="list-item sidebar-link">
            Product
          </Link>
          <Link to="/HyDDEP-free-demo" className="list-item sidebar-link">
            Free Demo
          </Link>
        </ul>
      </div>
        <section id="main-form">
          <div className="container demo-form">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <h4 className="counters-title">HyDDEP</h4>
                  <p className="color-white">
                    Take a look at how HyDDEP can help uncover insights across
                    structured and unstructured data.
                  </p>
                  <p className="color-white">
                    This demo is customised around your needs. Get answers to
                    your questions, and find out why Dark Pools is the right
                    solution for you.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <FreeDemoForm />
              </div>
            </div>
          </div>
        </section>
    </div>
  );
};

export default NERoML;
