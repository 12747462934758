import React from "react";
import Financial_img from "../img/Financial-services.png";
import Government_img from "../img/Government.png";
import Manufacture_img from "../img/Manufacture.png";
import Telecommunication_img from "../img/Telecommunication.png";
import AI_video from "./assets/videos/lue-digital-ai-icon-and-futuritic-earth-sphere-net.mp4";

const Recources = () => {
  return (
    <div>
      <section
        id="hero"
        className="d-flex justify-cntent-center align-items-center"
      >
        <video
          className="video-mg"
          autoPlay
          muted
          loop
        >
          <source src={AI_video} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="carousel carousel-fade container"
          data-ride="carousel"
        >
          <div className="container-margin-sides">
            <div className="carousel-item active">
              <div className="carousel-container">
                <p
                  className="animated fadeInUp main-text-resources"
                  data-aos="fade-up"
                >
                  Resources
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-margin-sides">
          <div className="card-wrapper">
            <div class="card" data-aos="fade-down">
              <div className="center-image">
                <img
                  src={Financial_img}
                  alt="Avatar"
                  style={{ width: "70%" }}
                />
              </div>
              <div class="card-container">
                <p>
                  <strong>August 17, 2022</strong>
                </p>
                <h4 className="sub-main-title">
                  <strong>
                    White Paper: A New Technology Stack for a digital future
                  </strong>
                </h4>
              </div>
            </div>
            <div class="card" data-aos="fade-down">
              <div className="center-image">
                <img
                  src={Government_img}
                  alt="Avatar"
                  style={{ width: "70%" }}
                />
              </div>
              <div class="card-container">
                <p>
                  <strong>July 17, 2021</strong>
                </p>
                <h4 className="sub-main-title">
                  <strong>
                    Anti-money laundering (AML): Anti-money laundering
                    regulations
                  </strong>
                </h4>
              </div>
            </div>
            <div class="card" data-aos="fade-down">
              <div className="center-image">
                <img
                  src={Manufacture_img}
                  alt="Avatar"
                  style={{ width: "70%" }}
                />
              </div>
              <div class="card-container">
                <p>
                  <strong>April 21, 2021</strong>
                </p>
                <h4 className="sub-main-title">
                  <strong>
                    Emerging technologies: The Power of AI and Machine Learning
                  </strong>
                </h4>
              </div>
            </div>
            <div class="card" data-aos="fade-down">
              <div className="center-image">
                <img
                  src={Telecommunication_img}
                  alt="Avatar"
                  style={{ width: "70%" }}
                />
              </div>
              <div class="card-container">
                <p>
                  <strong>Arpil 21, 2021</strong>
                </p>
                <h4 className="sub-main-title">
                  <strong>
                    The Telecom Industry? Uses advanced algorithms to look for
                    patterns
                  </strong>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Recources;
