import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Other from "./assets/images/contact-us/enquire-white.png";
import Alliances from "./assets/images/contact-us/alliance-white.png";
import Investor from "./assets/images/contact-us/investors-white.png";
import Sales from "./assets/images/contact-us/sales-white.png";
import { IconContext } from "react-icons";
import { BsArrowRight } from "react-icons/bs";
import "../App.css";

const ContactUs = () => {
  return (
      <main id="main" className="mt-100">
        <section
          className="contact section-bgprimary"
          data-aos="fade-up"
          data-aos-easing="ease-in-out"
          data-aos-duration="500"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="title-size">Contact Us</h3>
                <h4 className="site-text padding-height-bottom">
                  How can we help you?
                </h4>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="contact-box single-box">
                  <Link to="/sales-contact-form">
                    <div className="contact-wrapper">
                      <div className="text-grouping">
                        <h3 className="title">Sales</h3>
                        <p>
                          Learn how our industry-leading Enterprise software
                          products can help your organization.
                        </p>
                      </div>
                      <div className="item-image-box">
                        <img src={Sales} alt="Avatar" />
                      </div>
                    </div>
                    <div className="content-wrapper">
                      <p className="contact-bottom-text">Contact Sales </p>
                      <IconContext.Provider
                        value={{
                          color: "white",
                          className: "global-class-name",
                        }}
                      >
                        <div>
                          <BsArrowRight />
                        </div>
                      </IconContext.Provider>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-box single-box">
                  <Link to="/alliances-contact-form">
                    <div className="contact-wrapper">
                      <div className="text-grouping">
                        <h3 className="title">Alliances</h3>
                        <p>
                          Interested in becoming a Dark Pools partner? Contact
                          our Alliances team.
                        </p>
                      </div>
                      <div className="item-image-box">
                        <img src={Alliances} alt="Avatar" />
                      </div>
                    </div>
                    <div className="content-wrapper">
                      <p className="contact-bottom-text">Contact Alliances </p>
                      <IconContext.Provider
                        value={{
                          color: "white",
                          className: "global-class-name",
                        }}
                      >
                        <div>
                          <BsArrowRight />
                        </div>
                      </IconContext.Provider>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-box single-box">
                  <Link to="/investors-contact-form">
                    <div className="contact-wrapper">
                      <div className="text-grouping">
                        <h3 className="title">Investors Relation</h3>
                        <p>
                          Contact us to learn more about investing in Dark
                          Pools.
                        </p>
                      </div>
                      <div className="item-image-box">
                        <img src={Investor} alt="Avatar" />
                      </div>
                    </div>
                    <div className="content-wrapper">
                      <p className="contact-bottom-text">Contact Investors </p>
                      <IconContext.Provider
                        value={{
                          color: "white",
                          className: "global-class-name",
                        }}
                      >
                        <div>
                          <BsArrowRight />
                        </div>
                      </IconContext.Provider>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-box single-box">
                  <Link to="/inquires-contact-form">
                    <div className="contact-wrapper">
                      <div className="text-grouping">
                        <h3 className="title">Other Enquiries</h3>
                        <p>For all other questions, please contact us here.</p>
                      </div>
                      <div className="item-image-box">
                        <img src={Other} alt={Other} />
                      </div>
                    </div>
                    <div className="content-wrapper">
                      <p className="contact-bottom-text">
                        Contact Other Enquiries
                      </p>
                      <IconContext.Provider
                        value={{
                          color: "white",
                          className: "global-class-name",
                        }}
                      >
                        <div>
                          <BsArrowRight />
                        </div>
                      </IconContext.Provider>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="contact"
          data-aos="fade-up"
          data-aos-easing="ease-in-out"
          data-aos-duration="700"
        >
          <div className="container">
            <div className="row office-row">
              <div className="office-box-1" data-aos="fade-up">
                <h4 className="title-size">Dark Pools Office</h4>
              </div>
              <div className="office-box" data-aos="fade-up">
                <h4 className="office-title">Address Details:</h4>
                <p className="office-description">
                  6 North Road, <br />
                  Dunkeld Office Park, <br />
                  Johannesburg, <br />
                  2196
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
  );
};

export default ContactUs;
