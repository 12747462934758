import React from "react";
import Navigation from "../components/home-page/NavigationSection";
import RetailProductPage from "../components/industries/retail/RetailProductPage";
import MinimalFooter from "../components/footer-section/MinimalFooter";

const RetailProducts = () => {
  return (
    <div>
      <Navigation />
        <RetailProductPage />
      <MinimalFooter />
    </div>
  );
};

export default RetailProducts;
