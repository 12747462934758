import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import video from "../../assets/videos/Gov_Header.mp4";
import network from "../../assets/images/Product-images/Images-1.png";
import server from "../../assets/images/Product-images/Images-3.png";
import aiVision from "../../assets/images/Product-images/AI-Vision.png";
import { keyAreaProducts } from "./key-areas";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IconContext } from "react-icons";
import "./government-styles.css";
import "../styles.css";

const GovernmentProducts = () => {
  const [keyPoints, setKeyPoints] = useState(keyAreaProducts[0].keyIssues);

  const openBlock = (e, data, i) => {
    let issueBlock = document.getElementsByClassName("issue-block");
    for (i = 0; i < issueBlock.length; i++) {
      issueBlock[i].className = issueBlock[i].className.replace(" active", " ");
    }
    e.currentTarget.className += " active";
    setKeyPoints(data.keyIssues);
  };

  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <Link to="/government" className="list-item sidebar-link">
            Overview
          </Link>
          <Link to="/government-products" className="list-item sidebar-link">
            Products
          </Link>
          <Link to="/industry-demo-form" className="list-item sidebar-link">
            Free Demo
          </Link>
        </ul>
      </div>

      <section
        id="hero"
        className="d-flex justify-content-center align-items-center"
      >
        <video autoPlay muted loop>
          <source src={video} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="carousel carousel-fade container"
          data-ride="carousel"
        >
          <div className="container-margin-sides">
            <div className="carousel-item active">
              <h2 className="darkpools-heading animated fadeInDown">
                <span>Dark Pools</span>
              </h2>
              <p className="animated fadeInUp main-text">Government Products</p>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section className="about-section">
          <div className="container-margin-sides">
            <div className="block">
              <div className="content-section">
                <span id="overview-heading">Industry Problem</span>
                <p className="overview-text">
                  Government entities struggle to sift through massive amounts
                  of data to find timely insights. This becomes harder as bad
                  actors often employ sophisticated techniques to cover their
                  tracks and agencies end up wasting inordinate time and
                  resources on false alerts and low priority cases. Government
                  usually get an incomplete picture with intermittent data and
                  have to make recommendations based partly on data and largely
                  on intuition. Moreover, collaboration gets harder in the
                  absence of a common platform for data exploration and
                  visualization.
                </p>
              </div>
              <div className="content-section">
                <div className="product-container-industry">
                  <div className="text-box-left">
                    <span id="overview-heading">AIMLow</span>
                    <p className="overview-text">
                      Helps government get exponentially more value out of their
                      data. From identifying fraud, waste and abuse to
                      forecasting surges, enabling government agencies to
                      deliver and better drive their operations and Processes.
                    </p>
                  </div>
                  <div>
                    <img src={aiVision} alt={aiVision} width="320px" />
                  </div>
                </div>
              </div>
              <div className="content-section">
                <div className="product-container-industry">
                  <div className="text-box-left">
                    <img src={network} alt={network} width="320px" />
                  </div>
                  <div>
                    <span id="overview-heading">NERoML</span>
                    <p className="overview-text">
                      Identify insights and generate value from connected data,
                      clearly define patterns and trends Faster for executable
                      actions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="content-section">
                <div className="product-container-industry">
                  <div className="text-box-left">
                    <span id="overview-heading">HyDDEP</span>
                    <p className="overview-text">
                      Allows departments to link, share and reuse data across
                      database silos and datasets. This empowers government to
                      use data as a strategic asset, creating agility and
                      resilience. That reduces wastage, cost, time to value and
                      the risk of a data breach.
                    </p>
                  </div>
                  <div>
                    <img src={server} alt={server} width="320px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="middle-section">
          <div id="issue-table" className="container-margin-sides">
            <div className="block">
              <div className="product-panel-block">
                {keyAreaProducts.map((data, i) => (
                  <span
                    className="issue-block"
                    key={i}
                    onClick={(e) => openBlock(e, data, i)}
                  >
                    {data.title}
                  </span>
                ))}
              </div>
              <div className="gov-panel-content">
                <div className="p-tag">
                  <p>Key Issues</p>
                </div>
                <div className="key-issues-container">
                  {keyPoints.map((keyIssue, i) => (
                    <div className="key-issues" key={i}>
                      <IconContext.Provider
                        value={{
                          color: "green",
                          className: "global-class-name",
                        }}
                      >
                        <IoMdCheckmarkCircleOutline />
                      </IconContext.Provider>
                      <span id="spacing">{keyIssue}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default GovernmentProducts;
