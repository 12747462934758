import React from "react";
import Navigation from "../components/home-page/NavigationSection";
import FinancialProducts from "../components/industries/finance/FinancialServiceProductPage";
import MinimalFooter from "../components/footer-section/MinimalFooter";

const FinancialProduct = () => {
  return (
    <div>
      <Navigation />
      <FinancialProducts />
      <MinimalFooter />
    </div>
  );
};

export default FinancialProduct;
