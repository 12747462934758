import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import FreeDemoForm from "../FreeDemoForm";
import "../styles.css";

const AIMLoWDemo = () => {
  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <Link to="/AIMLoW" className="list-item sidebar-link">
            Overview
          </Link>
          <Link to="/AIMLoW-products" className="list-item sidebar-link">
            Product
          </Link>
          <Link to="/AIMLoW-free-demo" className="list-item sidebar-link">
            Free Demo
          </Link>
        </ul>
      </div>

      <main id="main-form">
        <section className="contact">
          <div className="container demo-form">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <h4 className="counters-title">AIMLow</h4>
                  <p className="color-white">
                    Take a look at how AIMLow can help uncover insights
                    through advance analytics on structured and unstructured data.
                  </p>
                  <p className="color-white">
                    This demo is customised around your needs. Get answers to
                    your questions, and find out why Dark Pools is the right
                    solution for you.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <FreeDemoForm />
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default AIMLoWDemo;
