import React from "react";
import Navigation from "../components/home-page/NavigationSection";
import MinimalFooter from "../components/footer-section/MinimalFooter";
import ContactFormAlliances from "../components/contact-page/ContactFormAlliances";

const ContactAlliances = () => {
  return (
    <div>
      <Navigation />
      <ContactFormAlliances />
      <MinimalFooter />
    </div>
  );
};

export default ContactAlliances;
