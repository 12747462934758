import React from "react";
import CodeOfConduct from "./components/CodeOfConduct";
import MinimalFooter from "./components/footer-section/MinimalFooter";
import Navigation from "./components/home-page/NavigationSection";

const CodeOfConductPage = () => {
  return (
    <div>
      <Navigation />
      <CodeOfConduct />
      <MinimalFooter />
    </div>
  )
}

export default CodeOfConductPage;