import LandingPage from "../components/home-page/LandingPage";
import HyDDEP from "../route_components/HyDDEP";
import HyDDEP_Products from "../route_components/HyDDEP_Products";
import AIMLow from "../route_components/AIMLow";
import AIMLow_Products from "../route_components/AIMLow_Products";
import NERoML from "../route_components/NERoML";
import NERoML_Products from "../route_components/NERoML_Products";
import FinancialServices from "../route_components/FinancialServices";
import FinancialProduct from "../route_components/FinancialProduct";
import Government from "../route_components/Government";
import GovernmentProducts from "../route_components/GovernmentProducts";
import Retail from "../route_components/Retail";
import RetailProducts from "../route_components/RetailProducts";
import Telecommunications from "../route_components/Telecommunication";
import TelecommunicationProducts from "../route_components/TelecommunicationProducts";
import Customers from "../Customers";
import ContactUsPage from "../ContactUsPage";
import ContactFormSales from "../components/contact-page/ContactFormSales";
import ContactFormAlliances from "../components/contact-page/ContactFormAlliances";
import ContactFormInvestors from "../components/contact-page/ContactFormInvestors";
import ContactFormOthers from "../components/contact-page/ContactFormOthers";
import NERoML_freedemo from "../route_components/NERoML_Freedemo";
import HYDDEP_freedemo from "../route_components/HYDDEP_Freedemo";
import AIMLoW_Freedemo from "../route_components/AIMLoW_Freedemo";
import IndustryDemoForm from "../route_components/IndustryDemoForm";
import PrivacyPolicyPage from "../PrivacyPolicyPage";
import TermsOfUsePage from "../TermsOfUsePage";
import CodeOfConductPage from "../CodeOfConductPage";
import SiteMapPage from "../SiteMapPage";
import CompanyPage from "../CompanyPage";
import NewsPage from "../NewsPage";
import RecourcesPage from "../RecourcesPage";
import ContactSale from "../route_components/ContactSale";
import ContactInvestors from "../route_components/ContactInvestors";
import ContactAlliances from "../route_components/ContactAlliances";
import ContactOthers from "../route_components/ContactOthers";

export default [
  {
    path: "/HyDDEP",
    name: "HyDDEP",
    component: HyDDEP,
  },
  {
    path: "/HyDDEP-products",
    name: "HyDDEPProducts",
    component: HyDDEP_Products,
  },
  {
    path: "/AIMLow",
    name: "AIMLow",
    component: AIMLow,
  },
  {
    path: "/AIMLow-products",
    name: "AIMLowProducts",
    component: AIMLow_Products,
  },
  {
    path: "/NERoML",
    name: "NERoML",
    component: NERoML,
  },
  {
    path: "/NERoML-products",
    name: "NERoMLProducts",
    component: NERoML_Products,
  },
  {
    path: "/financial-services",
    name: "FinancialServices",
    component: FinancialServices,
  },
  {
    path: "/financial-products",
    name: "FinancialProduct",
    component: FinancialProduct,
  },
  {
    path: "/government",
    name: "Government",
    component: Government,
  },
  {
    path: "/government-products",
    name: "GovernmentProduct",
    component: GovernmentProducts,
  },
  {
    path: "/retail",
    name: "Retail",
    component: Retail,
  },
  {
    path: "/retail-products",
    name: "RetailProducts",
    component: RetailProducts,
  },
  {
    path: "/telecom",
    name: "Telecommunication",
    component: Telecommunications,
  },
  {
    path: "/telecom-products",
    name: "TelecommunicationProduct",
    component: TelecommunicationProducts,
  },
  {
    path: "/customers",
    name: "Customers",
    component: Customers,
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    component: ContactUsPage,
  },
  {
    path: "/sales-contact-form",
    name: "ContactFormSales",
    component: ContactSale,
  },
  {
    path: "/alliances-contact-form",
    name: "ContactFormAlliances",
    component: ContactAlliances,
  },
  {
    path: "/investors-contact-form",
    name: "ContactFormInvestors",
    component: ContactInvestors,
  },
  {
    path: "/inquires-contact-form",
    name: "ContactFormOthers",
    component: ContactOthers,
  },
  {
    path: "/NERoML-free-demo",
    name: "NERoMLFreedemo",
    component: NERoML_freedemo,
  },
  {
    path: "/HYDDEP-free-demo",
    name: "HYDDEPFreedemo",
    component: HYDDEP_freedemo,
  },
  {
    path: "/AIMLoW-free-demo",
    name: "AIMLoWFreedemo",
    component: AIMLoW_Freedemo,
  },
  {
    path: "/industry-demo-form",
    name: "IndustryDemoForm",
    component: IndustryDemoForm,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyPage,
  },
  {
    path: "/terms-of-use",
    name: "TermsOfUsePage",
    component: TermsOfUsePage,
  },
  {
    path: "/code-of-conduct",
    name: "CodeOfConductPage",
    component: CodeOfConductPage,
  },
  {
    path: "/sitemap",
    name: "SiteMapPage",
    component: SiteMapPage,
  },
  {
    path: "/company",
    name: "CompanyPage",
    component: CompanyPage,
  },
  {
    path: "/news",
    name: "NewsPage",
    component: NewsPage,
  },
  {
    path: "/resources",
    name: "RecourcesPage",
    component: RecourcesPage,
  },
  {
    path: "/",
    name: "Home",
    component: LandingPage,
  },
];
