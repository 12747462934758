import React from "react";
import Navigation from "./components/home-page/NavigationSection";
import SiteMap from "./components/SiteMap";

const SiteMapPage = () => {
  return (
    <div>
      <Navigation />
      <SiteMap />
    </div>
  );
};

export default SiteMapPage;
