import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import network from "../../assets/images/Product-images/Images-3.png";
import secure from "../../assets/images/Product-images/Images-9.png";
import Data_Lake from "../../assets/images/product images/Images-1.png";
import Data_connector from "../../assets/images/product images/Images-2.png";
import HyDDEP_Header from "../../assets/videos/HyDDEP_Header.mp4";
import HyDDEP_image from "../../assets/images/main-logos/Hyddep.png";
import "../styles.css";

const HyddepPageProduct = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <Link to="/HyDDEP" className="list-item sidebar-link">
            Overview
          </Link>
          <Link to="/HyDDEP-products" className="list-item sidebar-link">
            Product
          </Link>
          <Link to="/HyDDEP-free-demo" className="list-item sidebar-link">
            Free Demo
          </Link>
        </ul>
      </div>

      <section
        id="hero"
        className="d-flex justify-content-center align-items-center"
      >
        <video autoPlay muted loop>
          <source src={HyDDEP_Header} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-ride="carousel"
        >
          <div className="container-margin-sides">
            <div className="carousel-item active">
              <div className="carousel-container">
                <img
                  src={HyDDEP_image}
                  alt={HyDDEP_image}
                  className="main-logo"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section class="features">
          <div class="container">
            <div className="container-margin-sides">
              <div className="row margin-side">
                <h2 className="overview-heading">
                  <strong>Data Integration Tool</strong>
                </h2>
                <p className="overview-text">
                  HyDDEP provides a variety of pre-built data integrations to
                  access on-prem and cloud data sources. Developers and data
                  engineers can directly utilise the connectors to integrate
                  data on HyDDEP without developing any custom integrations.
                </p>
              </div>
              <div class="row" data-aos="fade-up">
                <div class="row" data-aos="fade-up">
                  <div className="products-container">
                    <div class="product-image-wrapper">
                      <img src={network} class="img-fluid" alt={network} />
                    </div>
                    <div class="product-text-wrapper">
                      <h3 className="overview-heading">
                        <strong>Unified Federated Data Image</strong>
                      </h3>
                      <p class="overview-text">
                        HyDDEP® enables building, navigating, and exploring a
                        unified federated image of data entities across
                        technologies.
                      </p>
                      <ul className="overview-text-list">
                        <li className="overview-list">
                          Unify your enterprise and external data on a common
                          federated data image leveraging HyDDEP® model based
                          architecture
                        </li>
                        <li className="overview-list">
                          Choose from pre-built enterprise models to unify your
                          data
                        </li>
                        <li className="overview-list">
                          Explore, navigate, and visuaise your data model using
                          HyDDEP®
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="row " data-aos="fade-up">
                  <div className="products-container">
                    <div class="product-image-wrapper">
                      <img src={Data_Lake} class="img-fluid" alt={Data_Lake} />
                    </div>
                    <div class="product-text-wrapper">
                      <div className="overview">
                        <h3 className="overview-heading">
                          <strong>Virtual Data Lake</strong>
                        </h3>
                        <p class="overview-text">
                          HyDDEP® enables data virtualiation for organizations
                          to leverage existing investments in data stores and
                          data lakes by unifying all data into a unified virtual
                          data image, eliminating the need to duplicate data.
                        </p>
                      </div>
                      <ul className="overview-text-list">
                        <li className="overview-list">
                          Present a unified virtual layer of data from disparate
                          sources and provide to users as if from a single
                          source
                        </li>
                        <li className="overview-list">
                          Create, update, or delete data for any object
                        </li>
                        <li className="overview-list">
                          Invoke analytics to analyze and explore data on
                          HyDDEP®
                        </li>
                        <li className="overview-list">
                          Infer and expose metadata from source schemas
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="row" data-aos="fade-up">
                  <div className="products-container">
                    <div class="product-image-wrapper">
                      <img
                        src={Data_connector}
                        class="img-fluid"
                        alt={Data_connector}
                      />
                    </div>
                    <div class="product-text-wrapper">
                      <h3 className="overview-heading">
                        <strong>Data Connectors</strong>
                      </h3>
                      <p class="overview-text">
                        HyDDEP® provides a variety of data integrations to
                        access cloud and on-premise data sources. Developers and
                        data engineers can directly utilise the connectors to
                        integrate data on AIMLow® without developing any custom
                        integrations.
                      </p>
                      <ul className="overview-text-list">
                        <li className="overview-list">
                          Databases and big-data stores including Snowflake,
                          Impala, HBase, Postgres, Cosmos DB, MongoDB, Oracle,
                          AWS RedShift, SQL Server
                        </li>
                        <li className="overview-list">
                          Cloud applications including Salesforce, HubSpot
                        </li>
                        <li className="overview-list">
                          Queue-based systems including Apache Kafka, Azure
                          Event Hubs, Azure Topics, AWS Kinesis, AWS SQL
                        </li>
                        <li className="overview-list">
                          File systems including AWS S3, Azure Data Lake Store
                          gen2, Azure Blob, HDFS, local file system
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="row" data-aos="fade-up">
                  <div className="products-container">
                    <div class="product-image-wrapper">
                      <img src={secure} class="img-fluid" alt={secure} />
                    </div>
                    <div class="product-text-wrapper">
                      <h3 className="overview-heading">
                        <strong>Enterprise Object Models</strong>
                      </h3>
                      <p class="overview-text">
                        Dark Pools offers a variety of pre-built object models
                        that can accelerate development of enterprise AI
                        applications
                      </p>
                      <ul className="overview-text-list">
                        <li className="overview-list">
                          Industry Object Models comprise predefined integration
                          canonicals, data models, and time-series for
                          industries such as oil and gas, utilities, financial
                          services, Logistics and supply-chain, manufacturing,
                          retail and More
                        </li>
                        <li className="overview-list">
                          Functional Packages comprise supervised & unsupervised
                          ML models, an ML interpretability framework, and user
                          interface tooling to address high-value use cases
                        </li>
                        <li className="overview-list">
                          ML Packages contain methods for predictive analytics
                          such as anomaly detection, NLP, object detection, and
                          forecasting
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="container-height section-bg"
          data-aos="fade-up"
          date-aos-delay="200"
        >
          <div className="container">
            <div className="container-margin-sides">
              <div className="row">
                <h4 className="col-md-12 col-lg-12 demo-h4">
                  Sign up for a Free Demo
                </h4>
              </div>

              <div className="row button-spacing">
                <button class="button-28" value="button" onClick={() => navigate("/HyDDEP-free-demo")}>
                  HyDDEP - Free Demo
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default HyddepPageProduct;
