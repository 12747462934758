import React from "react";
import Navigation from "../components/home-page/NavigationSection";
import TelecommunicationPage from "../components/industries/telecommunication/TelecomServicesPage";
import MinimalFooter from "../components/footer-section/MinimalFooter";

const Telecommunications = () => {
  return (
    <div>
      <Navigation />
      <TelecommunicationPage />
      <MinimalFooter />
    </div>
  );
};

export default Telecommunications;
