import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import HyDDEP_Header from "../../assets/videos/HyDDEP_Header.mp4";
import HyDDEP_image from "../../assets/images/main-logos/Hyddep.png";
import "../styles.css";

const HyddepPage = () => {
  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
        <Link to="/HyDDEP" className="list-item sidebar-link">
            Overview
          </Link>
          <Link to="/HyDDEP-products" className="list-item sidebar-link">
            Product
          </Link>
          <Link to="/HyDDEP-free-demo" className="list-item sidebar-link">
            Free Demo
          </Link>
        </ul>
      </div>

      <section
        id="hero"
        className="d-flex justify-content-center align-items-center"
      >
        <video autoPlay muted loop>
          <source src={HyDDEP_Header} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-ride="carousel"
        >
          <div className="container-margin-sides">

          <div className="carousel-item active">
            <div className="carousel-container">
              <img
                src={HyDDEP_image}
                alt={HyDDEP_image}
                className="main-logo"
                />
            </div>
                </div>
          </div>
        </div>
      </section>

      <main id="main-form">
        <section className="about" data-aos="fade-up">
          <div className="container">
            <div className="container-margin-sides">

            <div className="row">
              <p className="overview-text">
                HyDDEP® is a one stop platform for data integration, ingesting
                data from multiple data sources to create unified sets of
                information for both operational and analytical uses for better
                exploration. HyDDEP ® produces consolidated data sets that are
                clean and consistent and meet the requirements of different use
                cases.
              </p>
            </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default HyddepPage;
