import React, { Fragment } from "react";
import Company from "./components/Company";
import MinimalFooter from "./components/footer-section/MinimalFooter";
import Navigation from "./components/home-page/NavigationSection";

const CompanyPage = () => {
  return (
    <div>
      <Navigation />
      <Company />
      <MinimalFooter />
    </div>
  );
};

export default CompanyPage;
