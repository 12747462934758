import React from "react";
import Navigation from './NavigationSection';
import Home from './HomeSection';
import Footer from "../footer-section/FooterSection";

const LandingPage = () => {
  return (
    <div>
      <Navigation />
      <Home />
      <Footer />
    </div>
  );
};

export default LandingPage;
