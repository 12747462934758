import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import AIMLow_Header from "../../assets/videos/header.mp4";
import AIMLow_image from "../../assets/images/main-logos/AIMLow.png";
import images1 from "../../assets/images/Product-images/Images-14.png";
import images2 from "../../assets/images/Product-images/Images-15.png";
import images3 from "../../assets/images/Product-images/Images-16.png";
import "../styles.css";

const AimlowProduct = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <Link to="/AIMLoW" className="list-item sidebar-link">
            Overview
          </Link>
          <Link to="/AIMLoW-products" className="list-item sidebar-link">
            Product
          </Link>
          <Link to="/AIMLoW-free-demo" className="list-item sidebar-link">
            Free Demo
          </Link>
        </ul>
      </div>

      <section
        id="hero"
        className="d-flex justify-content-center align-items-center"
      >
        <video autoPlay muted loop>
          <source src={AIMLow_Header} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-ride="carousel"
        >
          <div className="container-margin-sides">
            <div className="carousel-item active">
              <div className="carousel-container">
                <img
                  src={AIMLow_image}
                  alt={AIMLow_image}
                  className="main-logo"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section class="features">
          <div class="container">
            <div className="container-margin-sides">
              <div class="row" data-aos="fade-up">
                <p className="overview-text">
                  AIMLow Customizable Dashboard insights are automated requiring
                  minimum human intervention through automation of workflows
                  within the workbench. Making use of procurement analytics data
                  models and procurement-specific scenarios, AIMLow can analyse
                  a large set of data using machine learning to check for
                  irregularities and fraud related anomalies, forecasts and
                  more.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="about" data-aos="fade-up">
          <div className="container">
            <div className="container-margin-sides">
              <div className="row">
                <div className="overview">
                  <p className="overview-text">
                    AIMLow Dashboards allows exploration and deep dives into a
                    variety of customizable widgets , anticipate and adapt to
                    changes within the data flow enabling visibility through
                    your operations.
                  </p>
                  <p className="overview-text">
                    Through large data analysis, data integration, machine
                    learning with the use of NERoML and HyDDEP providing data
                    transparency, insights and drill-in capabilities for dynamic
                    Dashboard exploration.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about" data-aos="fade-up">
          <div className="container">
            <div className="container-margin-sides">
              <div class="row " data-aos="fade-up">
                <div className="products-aimlow-container">
                  <div class="product-text-wrapper">
                    <h3 className="overview-heading">
                      <strong>How AIMLow Benefits from NERoML & HyDDEP</strong>
                    </h3>
                    <p className="overview-text">
                      These highly integrated products have resolved the
                      industry-wide problem of disconnected and decentralised
                      Structures. All Products use fundamental Machine Learning
                      algorithms to better define data and discover anomalies
                      which provide accurate visualizations, data representation
                      and Data Structures.
                    </p>
                  </div>
                  <div class="product-image-wrapper">
                    <img src={images2} class="img-fluid" alt={images2} />
                  </div>
                </div>
              </div>
              <div class="row " data-aos="fade-up">
                <div className="products-aimlow-container">
                  <div class="product-image-wrapper">
                    <img src={images1} class="img-fluid" alt={images1} />
                  </div>
                  <div class="product-text-wrapper">
                    <h3 className="overview-heading">
                      <strong>NERoML with AIMLow</strong>
                    </h3>
                    <p class="overview-text">
                      NERoML advanced graph algorithms that resolves complex
                      machine learning anomalies with the use of Data
                      Clustering, Similarity identification, Pattern Matching
                      and more to provide meaningful data understanding through
                      AIMLow's platform.
                    </p>
                  </div>
                </div>
              </div>
              <div class="row " data-aos="fade-up">
                <div className="products-aimlow-container">
                  <div class="product-text-wrapper">
                    <h3 className="overview-heading">
                      <strong>HyDDEP with AIMLow</strong>
                    </h3>
                    <p className="overview-text">
                      HyDDEP and AIMLow have revolutionised Business Analytics
                      through Data quality, management and exploration to ingest
                      meaningful information which is then Directly represented
                      in AIMLow's Platform
                    </p>
                  </div>
                  <div class="product-image-wrapper">
                    <img src={images3} class="img-fluid" alt={images3} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="container-height section-bg"
          data-aos="fade-up"
          date-aos-delay="200"
        >
          <div className="container">
            <div className="container-margin-sides">
              <div className="row">
                <h4 className="col-md-12 col-lg-12 demo-h4">
                  Sign up for a Free Demo
                </h4>
              </div>

              <div className="row button-spacing">
                <button class="button-28" value="button" onClick={() => navigate("/AIMLoW-free-demo")}>
                  AIMLow - Free Demo
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default AimlowProduct;
