import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import video from "../../assets/videos/header.mp4";
import AIMLow_image from "../../assets/images/main-logos/AIMLow.png";
import "../styles.css";

// #123e25 - dark green
// #276955 - dark green
const AimlowPage = () => {
  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <Link to="/AIMLoW" className="list-item sidebar-link">
            Overview
          </Link>
          <Link to="/AIMLoW-products" className="list-item sidebar-link">
            Product
          </Link>
          <Link to="/AIMLoW-free-demo" className="list-item sidebar-link">
            Free Demo
          </Link>
        </ul>
      </div>

      <section
        id="hero"
        className="d-flex justify-content-center align-items-center"
      >
        <video autoPlay muted loop>
          <source src={video} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="carousel carousel-fade container"
          data-ride="carousel"
        >
          <div className="container-margin-sides">
            <div className="carousel-item active">
              <div className="carousel-container">
                <img
                  src={AIMLow_image}
                  alt={AIMLow_image}
                  className="main-logo"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <main id="main-form">
        <section className="about" data-aos="fade-up">
          <div className="container">
            <div className="container-margin-sides">
              <div className="row">
                <p className="overview-text">
                  AIMLow makes use of intelligent automation technologies such
                  as supervised and unsupervised ML models with Analytic
                  capabilities which can help better identify emerging patterns
                  and thus provide a better view on a dynamic intuitive and
                  easy-to-use Dashboard.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default AimlowPage;
