import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import AIMLow_Header from "../assets/DP_logo_Animation_Final_AdobeExpress.mp4";
import Other from "../assets/images/contact-us/enquire-white.png";
import Alliance from "../assets/images/contact-us/alliance-white.png";
import Investor_Relation from "../assets/images/contact-us/investors-white.png";
import Sales from "../assets/images/contact-us/sales-white.png";
import Arrow from "../assets/images/home/Arrow.png";
import { data } from "./data";
import { IconContext } from "react-icons";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const Home = () => {
  const [dataList, setDataList] = useState([]);
  const [showContent, setShowContent] = useState(false);

  const openBlock = (e, value, i) => {
    let myArray = [];
    let list = {
      valueDescription: value.description,
      valueList: value.keyIssues,
    };
    myArray.push(list);
    // myArray.push(list);

    let itemImageIcon = document.getElementsByClassName("item-image-icon");
    for (i = 0; i < itemImageIcon.length; i++) {
      itemImageIcon[i].className = itemImageIcon[i].className.replace(
        " active",
        " "
      );
    }
    e.currentTarget.className += " active";
    setShowContent(true);
    setDataList(myArray);
  };
  return (
    <Fragment>
      <section
        id="hero"
        className="d-flex justify-content-center align-items-center"
      >
        <video autoPlay muted loop>
          <source src={AIMLow_Header} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-ride="carousel"
        >
          <div className="container-margin-sides">
            <div className="carousel-item active">
              <div className="carousel-container">
                {/* <h2 className="darkpools-heading animated fadeInDown">
                  <span>Dark Pools</span>
                </h2> */}
                <p className="logo-text animated fadeInDown"
            style={{ animationDelay: "14s"}}>
                  AI DRIVEN REAL-TIME SOFTWARE
                </p>
              </div>
            </div>
          </div>

          <div className="container-margin-sides">
            <div className="carousel-item">
              <div className="carousel-container">
                <h2 className="darkpools-heading animated fadeInDown">
                  <span>Dark Pools</span>
                </h2>
                <p className="main-text animated fadeInUp">
                  AI DRIVEN REAL-TIME FINANCIAL CRIMES DETECTION SOFTWARE
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <section className="services">
          <div className="container">
            <div className="container-margin-sides">
              <p className="home-page-content">
                Dark Pool's leadership and expertise in automated machine
                learning is helping industries and markets around the world
                effectively meet industry requirements while simultaneously
                delivering high value smart solutions that increase revenue,
                optimise operations, mitigate risk and personalise customer
                experiences and a variety of customisable anomaly detection.
              </p>
              <p className="home-page-content">
                Dark Pools orchestration enables intelligence driven automation,
                acceleration and transparency through every step of the data
                science lifecycle. It also provides companies with a completely
                flexible architecture specifically design around your Industry
                Business Ontology (IBO) through an extensible platform that
                scales to meet the complexity of service use cases.
              </p>
            </div>
          </div>
        </section>
        <section  data-aos="fade-up" date-aos-delay="200">
          <div className="container">
            <div className="container-margin-sides">
              <div className="animation-wrapper">
                <div className="animation-text box-1">
                  <h2 id="title_of_form_link-ml">Dark Pools ML Architecture</h2>
                </div>
                <div className="animation-container">
                  {data.map((value, i) => (
                    <div
                      className="item-image-icon"
                      key={i}
                      onClick={(e) => openBlock(e, value, i)}
                    >
                      <img
                        className={`data-img-1 animated delay-${
                          1 + i
                        }s fadeInDown`}
                        src={value.icon}
                        alt={value.icon}
                      />
                      <h2 className={`animated h2-xs delay-${1 + i}s fadeInUp`}>
                        {value.title}
                      </h2>
                      <img
                        className={`data-img-2 animated delay-${
                          1 + i
                        }s fadeInLeft`}
                        src={Arrow}
                        alt={Arrow}
                      />
                    </div>
                  ))}
                </div>
                {showContent && (
                  <div className="animation-content">
                    {dataList.map((value, i) => (
                      <div key={i}>
                        <p className="animation-content-p">
                          {value.valueDescription}
                        </p>
                        <ul className="animation-content-ul">
                          {value.valueList.map((list, i) => (
                            <li className="animation-content-li" key={i}>
                                <IconContext.Provider
                                  value={{
                                    color: "white",
                                    className: "global-class-name",
                                  }}
                                >
                                  <IoMdCheckmarkCircleOutline id="bullet-point" style={{width: "25px", height: "25px"}}/>
                                </IconContext.Provider>
                                <span id="spacing">{list}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="services home-page">
          <div className="container">
            <div className="container-margin-sides">
              <div className="heading-container">
                <h2 className="services-heading float-inUp">
                  Take the next step
                </h2>
              </div>
              <div className="form_row">
                <Link to="/sales-contact-form" className="box">
                  <div className="item-box-form" data-aos="fade-up">
                    <div className="item-image-box">
                      <img src={Sales} alt="Avatar" style={{ width: "20%" }} />
                    </div>
                    <h4 className="title_of_form_link">Contact Sales</h4>
                    <p className="description_of_form_link">
                      Learn how our industry leading Enterprise software
                      products can help your organization.
                    </p>
                  </div>
                </Link>
                <Link to="/alliances-contact-form" className="box">
                  <div
                    className="item-box-form"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div className="item-image-box">
                      <img
                        src={Alliance}
                        alt="Avatar"
                        style={{ width: "20%" }}
                      />
                    </div>
                    <h4 className="title_of_form_link">Contact Alliances</h4>
                    <p className="description_of_form_link">
                      Interested in becoming a Dark Pools partner? Contact our
                      Alliances team.
                    </p>
                  </div>
                </Link>
                <Link to="/investors-contact-form" className="box">
                  <div
                    className="item-box-form"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div className="item-image-box">
                      <img
                        src={Investor_Relation}
                        alt="Avatar"
                        style={{ width: "20%", color: "red" }}
                      />
                    </div>
                    <h4 className="title_of_form_link">Investor Relation</h4>
                    <p className="description_of_form_link">
                      Contact us to learn more about investing in Dark Pools.
                    </p>
                  </div>
                </Link>
                <Link to="/inquires-contact-form" className="box">
                  <div
                    className="item-box-form"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div className="item-image-box">
                      <img src={Other} alt="Avatar" style={{ width: "20%" }} />
                    </div>
                    <h4 className="title_of_form_link">Request Information</h4>
                    <p className="description_of_form_link">
                      For all other questions, please contact us here.
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default Home;
