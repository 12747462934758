import React from "react";

const CodeOfConduct = () => {
  return (
    <main id="main">
      <div className="container">
        <section>
          <div className="container-margin-sides">
            <h1 className="title-head" data-aos="fade-down">DARK POOLS (Pty) Ltd.</h1>
            <h2 className="sub-title" data-aos="fade-right">
              <strong>CODE OF BUSINESS CONDUCT AND ETHICS</strong>
            </h2>
            <h2 className="sub-title" data-aos="fade-right">
              <strong>APPROVED BY THE BOARD OF DIRECTORS</strong>
            </h2>
            <h2 className="sub-title" data-aos="fade-right">
              <strong>NOVEMBER 12, 2021</strong>
            </h2>
            <p className="terms-and-policy-text " data-aos="fade-up">
            Dark Pools (Pty) Ltd. (“<stron>Dark Pools</stron>”) is committed to conducting its affairs honestly and ethically. We expect every employee, officer and director to act with integrity, apply good judgment and observe the highest personal ethical standards in making business decisions.
            </p>
            <p className="terms-and-policy-text " data-aos="fade-up">
            Do not hesitate to ask questions about certain conduct, to voice concerns or to clarify grey areas. You should also be alert to possible violations and report them without fear of retaliation. See Section 12 below for instructions on how to ask questions or report violations.
            </p>
            <p className="terms-and-policy-text " data-aos="fade-up">
            Conduct that is dishonest, unethical, illegal or unsafe is not tolerated at Dark Pools. Any employee who violates the standards in this Code of Business Conduct and Ethics (this “Code”) may be subject to disciplinary action, that, depending on the nature of the violation and the history of the employee, may range from a warning or reprimand to termination of employment and, in appropriate cases, civil legal action or referral for criminal prosecution.
            </p>
            <p className="terms-and-policy-text " data-aos="fade-up">
            While this Code covers a wide range of business conduct, it is not the only document that addresses the conduct of Dark Pools employees, officers and directors. For instance, this Code references separate more detailed policies relating to Anti-Corruption and Insider Trading. Also, Dark Pools Employee Handbook includes policies relating to, among other things, harassment and discrimination. Finally, Dark Pools employees remain subject to the terms of the Confidential Information and Inventions Agreement and Arbitration Agreement that they signed in connection with their employment. If you have any questions about whether your behaviour or any behaviour you observe is appropriate, under this Code or any other Dark Pools policy, it is your responsibility to ask the General Counsel. See Section 15 below for instructions on how to ask questions or report violations
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="sub-title" data-aos="fade-right">
              <strong>HONEST AND ETHICAL CONDUCT</strong>
            </h2>
            <p className="main-content-paragraph" data-aos="fade-up">
            It is our policy to promote high standards of integrity by conducting our affairs in an honest and ethical manner. Dark Pools integrity and reputation depend on the fairness and integrity brought to the job by each person associated with us. Personal integrity and sound judgment are the foundation of Dark Pools corporate integrity.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="sub-title" data-aos="fade-right">
              <strong>2. LEGAL COMPLIANCE</strong>
            </h2>
            <p className="main-content-paragraph" data-aos="fade-up">
            Our success depends upon each employee operating within legal guidelines and cooperating with local, national and international authorities. We expect employees, officers and directors to understand the legal and regulatory requirements applicable to their business units and areas of responsibility and to be familiar with and comply with other Dark Pools policies relating to legal compliance, including Dark Pools Anti-Corruption Policy and Insider Trading Policy. Violation of domestic or foreign laws, rules and regulations may subject an individual, as well as Dark Pools, to civil and/or criminal penalties, and may be grounds for disciplinary action, up to and including termination of employment.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="sub-title" data-aos="fade-right">3. INTERNATIONAL BUSINESS LAWS</h2>
            <p className="main-content-paragraph" data-aos="fade-up">
            Our employees, officers and directors are expected to comply with the applicable laws in all countries to which they travel, in which they operate and where we otherwise do business, including laws prohibiting bribery, corruption or the conduct of business with specified individuals, companies or countries. The fact that, in some countries, certain laws are not enforced or that violation of those laws is not subject to public criticism will not be accepted as an excuse for noncompliance.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="sub-title" data-aos="fade-right">
              4. ANTITRUST
            </h2>
            <p className="main-content-paragraph" data-aos="fade-up">
            Antitrust laws are designed to protect the competitive process and impose severe penalties for certain types of violations, including criminal penalties. These laws are based on the premise that the public interest is best served by vigorous competition and will suffer from illegal agreements or collusion among competitors. Certain kinds of information, such as pricing, production and inventory, should not be exchanged with competitors, regardless of how innocent or casual the exchange may be.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="sub-title" data-aos="fade-right">
              5. CONFLICTS OF INTEREST
            </h2>
            <p className="main-content-paragraph" data-aos="fade-up">
            Dark Pools expects its employees, officers and directors to be free from influences that conflict with the best interests of Dark Pools or might deprive Dark Pools of their undivided loyalty in business dealings. Making judgments, taking decisions, or pursuing actions when facing a conflict of interest may make it difficult to perform duties objectively and effectively and may have legal or regulatory consequences. Employees, officers and directors should avoid situations where their personal interests (financial or otherwise) may conflict with or compromise Dark Pools interest, could potentially result in a conflict of interest or could otherwise have the appearance of impropriety. While it is not possible to list all potential conflicts of interest, some of the more common conflicts would include: (a) outside employment, consulting, or board service; (b) holding a financial or other interest in a customer, vendor or partner of Dark Pools, an entity seeking to do business with us or a competitor; or(c)soliciting or accepting gifts, favours, loans or preferential treatment from any person or entity that does business or seeks to do business with us. Additional detail on each of these potential conflicts of interest—as well as other areas of potential concern—may be found in the Dark Pools Employee Handbook. Employees, officers and directors must disclose any conflicts of interest to the General Counsel as soon as it is identified that there may be a conflict of interest and, wherever possible, before engaging in the conduct in question. If you are unsure whether certain conduct or actions may constitute a conflict of interest or lead to a conflict of interest, you should discuss with the General Counsel.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="sub-title" data-aos="fade-right">
              6. CORPORATE OPPORTUNITIES
            </h2>
            <p className="main-content-paragraph" data-aos="fade-up">
            You may not take personal advantage of opportunities for Dark Pools that are presented to you or discovered by you as a result of your position with us or through your use of corporate property or information. Even opportunities that are acquired privately by you may be questionable if they are related to our existing or proposed lines of business. Significant participation in an investment or outside business opportunity that is directly related to our current or planned lines of business must be pre-approved by the Chief Executive Officer. You may not use your position with us or corporate property or information for improper personal gain, nor should you compete with us in any way. If you have any concerns about how to comply with this policy, you should discuss with the General Counsel.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="sub-title" data-aos="fade-right">7. FINANCIAL INTEGRITY</h2>
            <p className="main-content-paragraph" data-aos="fade-up">
            The integrity of our records and public disclosure depends upon the validity, accuracy and completeness of the information supporting the entries to our books of account. Therefore, our corporate and business records should be completed accurately and honestly. The making of false or misleading entries is strictly prohibited. Our records serve as a basis for managing our business and are important in meeting our obligations to customers, suppliers, creditors, employees and others. We also rely upon our accounting and other business and corporate records in preparing publicly filed reports. Securities laws require that these reports provide full, fair, accurate, timely and understandable disclosure and fairly present our financial condition and results of operations. Employees who contribute in any way in preparing or verifying these reports should strive to ensure that our financial disclosure is complete, accurate and transparent. If you have any concerns about how to comply with this policy, or if you observe conduct or actions that do not seem to comply with this policy, you should immediately discuss such concerns with the General Counsel.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="sub-title" data-aos="fade-right">8. FAIR DEALING</h2>
            <p className="main-content-paragraph" data-aos="fade-up">
            Advantages over our competitors are to be obtained through superior performance of our products and services, not through unethical or illegal business practices. Statements regarding Dark Pools services must not be untrue, misleading, deceptive or fraudulent. Acquiring proprietary information from others through improper means, possessing trade secret information that was improperly obtained, or inducing improper disclosure of confidential information from employees of other companies is prohibited. You are expected to deal fairly with our customers, suppliers, employees and anyone else with whom you have contact in the course of performing your job. Employees involved in procurement have a special responsibility to adhere to principles of fair competition in the purchase of products and services by selecting suppliers based exclusively on normal commercial considerations, such as quality, cost, availability, service and reputation, and not on the receipt of special favours.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="sub-title" data-aos="fade-right">9. COMPANY ASSETS</h2>
            <p className="main-content-paragraph" data-aos="fade-up">
            All employees, officers and directors are expected to protect our assets and ensure their efficient use. Our property, such as office supplies, computer equipment, buildings and products, are to be used only for legitimate business purposes, although incidental personal use may be permitted. You may not use our corporate name, any brand name or trademark owned or associated with  Dark Pools or any letterhead stationery for any personal purpose.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="sub-title" data-aos="fade-right">10. CONFIDENTIALITY</h2>
            <p className="main-content-paragraph" data-aos="fade-up">
            As an employee, officer or director of Dark Pools, you may learn information about Dark Pools or other companies that is confidential and proprietary. You must take care to keep this information confidential. Materials that contain confidential information should be stored securely. Unauthorised posting or discussion of any information concerning our business, information or prospects on the Internet is prohibited. Be cautious when discussing sensitive information in public places like elevators, airports, restaurants and public areas within Dark Pools, such as cafeterias. All Dark Pools emails, voicemails and other communications are presumed confidential and should not be forwarded outside of Dark Pools, except where required for legitimate business purposes. Dark Pools employees are bound by the terms of the Confidential Information and Inventions Agreement or similar terms that they agree to in connection with their employment.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="sub-title" data-aos="fade-right">11. MEDIA/PUBLIC DISCUSSIONS</h2>
            <p className="main-content-paragraph" data-aos="fade-up">
            It is our policy to disclose material information concerning Dark Pools to the public only through specific limited channels to avoid inappropriate publicity and to ensure that all those with an interest in the company will have equal access to information. All inquiries or calls from the press and financial analysts should be referred to the Chief Financial Officer or the Chief Executive Officer.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="sub-title" data-aos="fade-right">12. QUESTIONS AND REPORTING POTENTIAL VIOLATIONS</h2>
            <p className="main-content-paragraph" data-aos="fade-up">
            If you are aware of a suspected or actual violation of this Code, you have a responsibility to promptly report it to the General Counsel. You may report violations of this Code, including concerns regarding Dark Pools auditing and accounting matters in one of the following ways:
            </p>
            <ul className="unordered-list" data-aos="fade-up">
                <li className="content-list">
                via electronic mail to the Company's General Counsel at legal@darkpools.co.za;
                </li>
                <li className="content-list">
                via regular mail to the Company at: 6 North Road, Dunkeld West, Johannesburg, South Africa 2196; Attn: General Counsel
                </li>
            </ul>
            <p className="main-content-paragraph" data-aos="fade-up">
            You may submit a report online anonymously if you prefer, but in that case the General Counsel will be unable to obtain follow-up details from you that may be necessary to investigate the matter. Whether you identify yourself or remain anonymous, your contact with the anonymous reporting service will be kept strictly confidential to the extent reasonably possible within the objectives of this Code. The General Counsel will investigate all reported possible Code violations promptly and with the highest degree of confidentiality that is possible under the specific circumstances. If any investigation indicates that a violation of this Code has probably occurred, we will take such action as we believe to be appropriate under the circumstances. If we determine that an employee, officer or director is responsible for a Code violation, he or she will be subject to disciplinary action up to, and including, termination and, in appropriate cases, civil action or referral for criminal prosecution. Please note that retaliation against employees reporting violations of this Code is prohibited and we will take prompt disciplinary action against any employee, officer or director who attempts to retaliate against you for reporting violations.
            </p>
          </div>
        </section>
      </div>
    </main>
  )
}

export default CodeOfConduct;