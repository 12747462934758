import React from "react";
import Navigation from "../components/home-page/NavigationSection";
import HyddepPage  from "../components/products/hyddep-page/HyddepPage";
import MinimalFooter from "../components/footer-section/MinimalFooter";

const HyDDEP = () => {
  return (
    <div>
      <Navigation />
      <HyddepPage />
      <MinimalFooter />
    </div>
  );
};

export default HyDDEP;
