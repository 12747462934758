import React from "react";
import MinimalFooter from "./components/footer-section/MinimalFooter";
import Navigation from "./components/home-page/NavigationSection";
import TermsOfUse from "./components/TermsOfUse";

const TermsOfUsePage = () => {
  return (
    <div>
      <Navigation />
      <TermsOfUse />
      <MinimalFooter />
    </div>
  );
};

export default TermsOfUsePage;
