import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import video from "../../assets/videos/neural-network-and-machine-learning-procedure.mp4";
import network from "../../assets/images/Product-images/Images-1.png";
import server from "../../assets/images/Product-images/Images-3.png";
import aiVision from "../../assets/images/Product-images/AI-Vision.png";
import { keyAreaProducts } from "./key-areas";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IconContext } from "react-icons";
import "./telecom-styles.css";
import "../styles.css";

const TelecommunicationProduct = () => {
  const [keyPoints, setKeyPoints] = useState(keyAreaProducts[0].keyIssues);

  const openBlock = (e, data, i) => {
    let issueBlock = document.getElementsByClassName("issue-block");
    for (i = 0; i < issueBlock.length; i++) {
      issueBlock[i].className = issueBlock[i].className.replace(" active", " ");
    }
    e.currentTarget.className += " active";
    setKeyPoints(data.keyIssues);
  };

  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <Link to="/telecom" className="list-item sidebar-link">
            Overview
          </Link>
          <Link to="/telecom-products" className="list-item sidebar-link">
            Products
          </Link>
          <Link to="/industry-demo-form" className="list-item sidebar-link">
            Free Demo
          </Link>
        </ul>
      </div>

      <section
        id="hero"
        className="d-flex justify-content-center align-items-center"
      >
        <video autoPlay muted loop>
          <source src={video} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="carousel carousel-fade container"
          data-ride="carousel"
        >
          <div className="container-margin-sides">
            <div className="carousel-item active">
              <h2 className="darkpools-heading animated fadeInDown">
                <span>Dark Pools</span>
              </h2>
              <p className="animated fadeInUp main-text">
                Telecommunication Products
              </p>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section className="about-section">
          <div className="container-margin-sides">
            <div className="block">
              <div className="content-section">
                <span id="overview-heading">Industry Problem</span>
                <p className="overview-text">
                  Enforcement actions and penalties for non-compliance with AML
                  regulations are on the rise. Banks are saddled with
                  increasingly higher costs for transaction monitoring or facing
                  the risks of hefty fines. At the same time bad actors are
                  employing an increasingly diverse set of strategies to stay
                  ahead of regulators and compliance departments. Current
                  rules-based AML software creates a slew of cases that require
                  extensive and manual investigation by compliance officers.
                </p>
              </div>
              <div className="content-section">
                <div className="product-container-industry">
                  <div className="text-box-left">
                    <span id="overview-heading">AIMLow</span>
                    <p className="overview-text">
                      Emulate the tasks of data scientists to automate the
                      entire predictive process. Predict what can happen and how
                      likely it is to happen and understand the variables
                      affecting the outcomes and display them in Analytical
                      Charts types through our dashboards.
                    </p>
                  </div>
                  <div>
                    <img src={aiVision} alt={aiVision} width="320px" />
                  </div>
                </div>
              </div>
              <div className="content-section">
                <div className="product-container-industry">
                  <div className="text-box-left">
                    <img src={network} alt={network} width="320px" />
                  </div>
                  <div>
                    <span id="overview-heading">NERoML</span>
                    <p className="overview-text">
                      Combine geospatial and network datasets and dynamically
                      visualise it to compare coverage, existing infrastructure,
                      and plan future investments with drill-in and reporting
                      features. Apply models around activation, churn, RF
                      propagation, and network prioritization to improve network
                      planning decisions and scenario analysis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="content-section">
                <div className="product-container-industry">
                  <div className="text-box-left">
                    <span id="overview-heading">HyDDEP</span>
                    <p className="overview-text">
                      Complex data formed into diagrams and pictures that reveal
                      truths which aren’t immediately obvious. Merge huge
                      amounts of real-time data from numerous data sources into
                      a single picture allowing you to tap into critical
                      information, reduce redundant data and optimise efficiency
                      which can be used to improve business functions, processes
                      and decision making.
                    </p>
                  </div>
                  <div>
                    <img src={server} alt={server} width="320px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="middle-section">
          <div id="issue-table" className="container-margin-sides">
            <div className="block">
              <div className="product-panel-block">
                {keyAreaProducts.map((data, i) => (
                  <span
                    className="issue-block"
                    key={i}
                    onClick={(e) => openBlock(e, data, i)}
                  >
                    {data.title}
                  </span>
                ))}
              </div>
              <div className="telecom-panel-content">
                <div className="p-tag">
                  <p>Key Issues</p>
                </div>
                <div className="key-issues-container">
                  {keyPoints.map((keyIssue, i) => (
                    <div className="key-issues" key={i}>
                      <IconContext.Provider
                        value={{
                          color: "green",
                          className: "global-class-name",
                        }}
                      >
                        <IoMdCheckmarkCircleOutline />
                      </IconContext.Provider>
                      <span id="spacing">{keyIssue}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default TelecommunicationProduct;
