import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import video from "../../assets/videos/global-finance.mp4";
import { keyAreaData } from "./key-areas";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IconContext } from "react-icons";
import image1 from "../../assets/images/industries/image1.png";
import "./finance-styles.css";
import "../styles.css";

const FinancialService = () => {
  const [keyPoints, setKeyPoints] = useState(keyAreaData[0].keyIssues);

  const openBlock = (e, data, i) => {
    let issueBlock = document.getElementsByClassName("issue-block");
    for (i = 0; i < issueBlock.length; i++) {
      issueBlock[i].className = issueBlock[i].className.replace(" active", " ");
    }
    e.currentTarget.className += " active";
    setKeyPoints(data.keyIssues);
  };

  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <Link to="/financial-services" className="list-item sidebar-link">
            Overview
          </Link>
          <Link to="/financial-products" className="list-item sidebar-link">
            Products
          </Link>
          <Link to="/industry-demo-form" className="list-item sidebar-link">
            Free Demo
          </Link>
        </ul>
      </div>

      <section
        id="hero"
        className="d-flex justify-cntent-center align-items-center"
      >
        <video autoPlay muted loop>
          <source src={video} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="carousel carousel-fade container"
          data-ride="carousel"
        >
          <div className="container-margin-sides">
            <div className="carousel-item active">
              <h2 className="darkpools-heading animated fadeInDown">
                <span>Dark Pools</span>
              </h2>
              <p className="animated fadeInUp main-text">Financial Services</p>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section className="about-section">
          <div className="container-margin-sides">
            <div className="block">
              <span id="overview-heading">
                Dark Pools Addresses Key Issues Across the Financial Service
                Value Chain
              </span>
              <p className="overview-text">
                Achieving desired Financial results in today's complex,
                connected world requires a high-performance blend of humans with
                machines, automation with artificial intelligence, business
                analytics with data science. Welcome to the Age where Dark Pools
                translates insights into performance through AI services,
                solutions, and capabilities.
              </p>
              <div className="inside-content-wrapper">
                <p className="overview-text">
                  Dark Pool's leadership and expertise in automated machine
                  learning is helping financial services around the world
                  effectively meet industry requirements while simultaneously
                  delivering high value smart solutions that increase revenue
                  assurance, optimise operations, mitigate risk, Detect
                  anomalies and personalise customer experiences.
                </p>
                <div className="image-wrapper">
                  <img src={image1} alt={image1} className="main-images" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="middle-section">
          <div id="issue-table" className="container-margin-sides ">
            <div className="block">
              <div className="panel-block">
                {keyAreaData.map((data, i) => (
                  <span
                    className="issue-block"
                    key={i}
                    onClick={(e) => openBlock(e, data, i)}
                  >
                    {data.title}
                  </span>
                ))}
              </div>
              <div className="finance-panel-content">
                <div className="p-tag">
                  <p>Key Issues</p>
                </div>
                <div className="key-issues-container">
                  {keyPoints.map((keyIssue, i) => (
                    <div className="key-issues" key={i}>
                      <IconContext.Provider
                        value={{
                          color: "green",
                          className: "global-class-name",
                        }}
                      >
                        <IoMdCheckmarkCircleOutline />
                      </IconContext.Provider>
                      {/* <input type="text" placeholder="Search.." /> */}
                      <span id="spacing">{keyIssue}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default FinancialService;
