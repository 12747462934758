import React, { useState } from "react";

const ContactFormInvestors = () => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    subject: "",
    textarea: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [valid, setValid] = useState(false);

  const handleFullNameInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      name: event.target.value,
    }));
  };

  const handleEmailInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      email: event.target.value,
    }));
  };

  const handleSubjectInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      subject: event.target.value,
    }));
  };

  const handleTextAreaInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      textarea: event.target.value,
    }));
  };

  const handleSubmit = () => {
    if (values.name && values.email && values.textarea) {
      setValid(true);
    }

    return true;
  };

  return (
    <main id="main">
      <section className="container">
        <div className="sales-form-container">
          <div className="sales-form-header">
            <h2 className="sales-form-text-top">Investor Relations</h2>
          </div>
          <form
            className="sales-free-demo-form"
            formName="investors"
            formValues={values}
            preSubmit={handleSubmit}
          >
            <div className="sales-inline-form">
              <div className="sales-row-form">
                <label className="text-font">Full Name:</label>
                <input
                  id="fullname"
                  className="sales-form-field-left"
                  type="text"
                  placeholder=""
                  name="name"
                  value={values.name}
                  onChange={handleFullNameInputChange}
                />
                {submitted && !values.name && (
                  <span className="form-errors" id="full-name-error">
                    Please enter a full name
                  </span>
                )}
              </div>
              <div className="sales-row-form">
                <label className="text-font">Work Email:</label>
                <input
                  id="email"
                  className="sales-form-field-right"
                  type="text"
                  placeholder=""
                  name="email"
                  value={values.email}
                  onChange={handleEmailInputChange}
                />
                {submitted && !values.email && (
                  <span className="form-errors" id="email-error">
                    Please enter an email address
                  </span>
                )}
              </div>
            </div>
            <div className="sales-row-form">
              <label className="text-font">Subject:</label>
              <input
                id="subject"
                className="sales-form-field"
                type="text"
                placeholder=""
                name="subject"
                value={values.subject}
                onChange={handleSubjectInputChange}
              />
            </div>

            <div className="sales-row-form">
              <label className="text-font">How can we help?</label>
              <textarea
                id="teaxtarea"
                className="area-form-field"
                type="textarea"
                placeholder=""
                name="textarea"
                value={values.textarea}
                onChange={handleTextAreaInputChange}
                rows="5"
                required
              />
            </div>
            <button className="button-form-field" type="submit">
              Get Free Demo
            </button>
            {submitted && (
              <div className="success-message">
                Your message has been sent. Thank you!
              </div>
            )}
          </form>
        </div>
      </section>
    </main>
  );
};

export default ContactFormInvestors;
