import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import MinimalFooter from "./footer-section/MinimalFooter";

const SiteMap = () => {
  return (
    <Fragment>
    <div id="site-map">
      <div className="site-map-top">
      <div className="container">
        <div className="container-margin-sides site-map-space">
            <div className="site-map-link">
              <h4>Company</h4> 
              <ul>
                <Link to="/"><p>Home</p></Link>
                <Link to="company"><p>About us</p></Link>
                <Link to="HyDDEP"><p>Services</p></Link>
              </ul>
            </div>

            <div className="site-map-link">
              <h4>Products</h4> 
              <ul>
                <Link to="/HyDDEP"><p>HyDDEP</p></Link>
                <Link to="/AIMLow"><p>AIMLow</p></Link>
                <Link to="/NERoML"><p>NERoML</p></Link>
              </ul>
            </div>

            <div className="site-map-link">
              <h4>Industries</h4>
              <ul>
                <Link to="/financial-services"><p>Financial Services</p></Link>
                <Link to="/government"><p>Government</p></Link>
                <Link to="/retail"><p>Retail</p></Link>
                <Link to="/telecom"><p>Telecommunication</p></Link>
              </ul>
            </div>

            <div className="site-map-link">
              <h4>Help</h4>
              <ul>
              <Link to="/contact-us"><p>Get Started</p></Link>
              <Link to="/news"><p>News</p></Link>
              <Link to="/resources"><p>Resources</p></Link>
              <Link to="/#"><p>Careers</p></Link>
              </ul>
            </div>

            <div className="site-map-link">
              <h4>Other</h4>
              <ul>
              <Link to="/privacy-policy"><p>Privacy Policy</p></Link>
              <Link to="/terms-of-use"><p>Terms of Use</p></Link>
              <Link to="/code-of-conduct"><p>Code of Conduct</p></Link>
              <Link to="/sitemap"><p>Site Map</p></Link>
              </ul>
            </div>
            
          </div>
          </div>
      </div>
    <MinimalFooter />
    </div>
    </Fragment>
  )
}

export default SiteMap;