import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import video from "../../assets/videos/Gov_Header.mp4";
import image from "../../assets/images/DarkPools white transparent images/Government-WHITE.png";
import { keyAreaData } from "./key-areas";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IconContext } from "react-icons";
import "./government-styles.css";
import "../styles.css";

const GovernmentService = () => {
  const [keyPoints, setKeyPoints] = useState(keyAreaData[0].keyIssues);

  const openBlock = (e, data, i) => {
    let issueBlock = document.getElementsByClassName("issue-block");
    for (i = 0; i < issueBlock.length; i++) {
      issueBlock[i].className = issueBlock[i].className.replace(" active", " ");
    }
    e.currentTarget.className += " active";
    setKeyPoints(data.keyIssues);
  };

  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <Link to="/government" className="list-item sidebar-link">
            Overview
          </Link>
          <Link to="/government-products" className="list-item sidebar-link">
            Products
          </Link>
          <Link to="/industry-demo-form" className="list-item sidebar-link">
            Free Demo
          </Link>
        </ul>
      </div>

      <section
        id="hero"
        className="d-flex justify-content-center align-items-center"
      >
        <video autoPlay muted loop>
          <source src={video} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="carousel carousel-fade container"
          data-ride="carousel"
        >
          <div className="container-margin-sides">
            <div className="carousel-item active">
              <h2 className="darkpools-heading animated fadeInDown">
                <span>Dark Pools</span>
              </h2>
              <p className="animated fadeInUp main-text">Government Services</p>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section className="about-section">
          <div className="container-margin-sides">
            <div className="block">
              <span id="overview-heading">
                Dark Pools AI Addresses Key Issues Across the Government Value
                Chain
              </span>
              <div className="box-wrapper">
                <div className="paragraph-container">
                  <p className="overview-text">
                    Dark Pools AI helps government agencies ingest and integrate
                    data from across multiple source systems and implement AI
                    models at scale to rapidly address their most pressing
                    needs. Our Product tailors for Readiness, Fraud Detection,
                    Anti-Money Laundering, Workforce Analytics, Intelligence
                    Analysis, and other high-impact use cases which can be
                    quickly customised and deployed, scaled across any defense,
                    public health, law enforcement, intelligence, or public
                    finance agency, to achieve their public service goals.
                  </p>
                  <div className="inside-content-wrapper">
                    <p className="inside-content-text">
                      Fraud and economic crime rates are at record highs and
                      continue to be a significant issue for all government
                      bodies. Agencies ranging from law enforcement, public
                      finance, or public health are dealing with a myriad of
                      fraud, waste, and abuse issues. But many of the issues
                      across these agencies are similar – data fragmented across
                      multiple silos, inadequate resources to investigate all
                      the cases, costly and lengthy investigation processes, and
                      coordination issues across investigative teams.
                    </p>
                    <div className="image-wrapper">
                      <img src={image} alt={image} className="main-images" />
                    </div>
                  </div>
                  <p className="overview-text">
                    Disruptive behavior in the Public Sector is a frustrating
                    problem facing government today. While more information is
                    available today, the data is siloed in fragmented systems
                    and hard to use in advanced analytics. At the same time,
                    cases are difficult to prioritise, and the case review
                    process can often get bogged down.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="middle-section">
          <div id="issue-table" className="container-margin-sides">
            <div className="block">
              <div className="panel-block">
                {keyAreaData.map((data, i) => (
                  <span
                    className="issue-block"
                    key={i}
                    onClick={(e) => openBlock(e, data, i)}
                  >
                    {data.title}
                  </span>
                ))}
              </div>
              <div className="gov-panel-content">
                <div className="p-tag">
                  <p>Key Issues</p>
                </div>
                <div className="key-issues-container">
                  {keyPoints.map((keyIssue, i) => (
                    <div className="key-issues" key={i}>
                      <IconContext.Provider
                        value={{
                          color: "green",
                          className: "global-class-name",
                        }}
                      >
                        <IoMdCheckmarkCircleOutline />
                      </IconContext.Provider>
                      <span id="spacing">{keyIssue}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default GovernmentService;
