export const keyAreaData = [
  {
    title: 'Telecommunication',
    keyIssues: ['Engagement tracking & big data challenges', 'Lack of consistency between price plan repositories', 'Analyst and specialised telecommunications approaches', 'Quick and Personalised Customer Service', ]
  },
]

export const keyAreaProducts = [
  {
    title: 'Key Capabilities',
    keyIssues: ['Dashboard Analytics', 'Data Exploration', 'Workflow Management toolkits', 'Anomaly Management', 'Workflow Management', 'Alerts, Cases and Reporting ']
  },
  {
    title: 'Use Cases',
    keyIssues: ['Product Optimization', 'Capital Asset Planning', 'Predictive Analytics', 'Real-time Analytics', 'Targeted Marketing', 'Fraud Detection']
  },
]