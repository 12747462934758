import React from "react";
import MinimalFooter from "./components/footer-section/MinimalFooter";
import Navigation from "./components/home-page/NavigationSection";
import Recources from "./components/Recources";

const RecourcesPage = () => {
  return (
    <div className="web-page">
        <Navigation />
        <Recources />
        <MinimalFooter />
    </div>
  )
}

export default RecourcesPage;