import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import NERoML_Header from "../../assets/videos/NERoML_Header.mp4";
import NERoML_image from "../../assets/images/main-logos/NERoML.png";
import ai from "../../assets/images/Product-images/Images-6.png";
import honeyComb from "../../assets/images/Product-images/Images-7.png";
import secure from "../../assets/images/Product-images/Images-9.png";
import global from "../../assets/images/Product-images/Images-10.png";
import security from "../../assets/images/Product-images/Images-12.png";
import securelyLocked from "../../assets/images/Product-images/Images-13.png";
import "../styles.css";

const NERoMLProductPage = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <Link to="/NERoML" className="list-item sidebar-link">
            Overview
          </Link>
          <Link to="/NERoML-products" className="list-item sidebar-link">
            Product
          </Link>
          <Link to="/NERoML-free-demo" className="list-item sidebar-link">
            Free Demo
          </Link>
        </ul>
      </div>

      <section
        id="hero"
        className="d-flex justify-content-center align-items-center"
      >
        <video autoPlay muted loop>
          <source src={NERoML_Header} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-ride="carousel"
        >
          <div className="container-margin-sides">
            <div className="carousel-item active">
              <div className="carousel-container">
                <img
                  src={NERoML_image}
                  alt={NERoML_image}
                  className="main-logo"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section class="features">
          <div class="container">
            <div class="container-margin-sides">
              <div class="row margin-side" data-aos="fade-up">
                <h2 className="overview-heading">
                  <strong>
                    Visualise dynamic data and accelerate efficacy
                  </strong>
                </h2>
              </div>
              <div class="row" data-aos="fade-up">
                <div class="row" data-aos="fade-up">
                  <div className="product-neroml-container">
                    <div class="product-image-wrapper">
                      <img src={global} class="img-fluid" alt={global} />
                    </div>
                    <div class="product-text-wrapper">
                      <h3 className="overview-heading">
                        <strong>Network visualisations</strong>
                      </h3>
                      <ul className="overview-text-list">
                        <li className="overview-list">
                          Use predictive relationship management across entities
                          (people, organizations, locations, things, and events)
                          in a time-varying, graph network visualization that
                          captures and maintains all temporal and spatial
                          relationships.
                        </li>
                        <li className="overview-list">
                          Review all interactions across entities and visualise
                          interaction density across time, discovering patterns
                          and temporal shifts.
                        </li>
                        <li className="overview-list">
                          Drill-in, merge, or de-duplicate entities directly on
                          the graph to manage and share knowledge with others.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="row" data-aos="fade-up">
                  <div className="product-neroml-container">
                    <div class="product-text-wrapper">
                      <div class="overview">
                        <h3 className="overview-heading">
                          <strong>AI-powered link analysis</strong>
                        </h3>
                        <ul className="overview-text-list">
                          <li className="overview-list">
                            Uncover hidden opportunities, targets, and complex
                            patterns via AI-based investigative link analysis,
                            advanced graph algorithms, and rich tooling.
                          </li>
                          <li className="overview-list">
                            Traverse step-wise through the network graph to
                            incrementally identify new insights.
                          </li>
                          <li className="overview-list">
                            Leverage AI recommendations to prioritise potential
                            threats, mitigate risks, and resolve anomalies.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="product-image-wrapper">
                      <img src={ai} class="img-fluid" alt={ai} />
                    </div>
                  </div>
                </div>

                <div class="row" data-aos="fade-up">
                  <div className="product-neroml-container">
                    <div class="product-image-wrapper">
                      <img src={honeyComb} class="img-fluid" alt={honeyComb} />
                    </div>
                    <div class="product-text-wrapper">
                      <div class="overview">
                        <h3 className="overview-heading">
                          <strong>Event-based exploration</strong>
                        </h3>
                        <ul className="overview-text-list">
                          <li className="overview-list">
                            Visualise and analyse dynamic interaction data
                            across time with the use of generated alert triggers
                            to spot patterns and accelerate investigation
                            efficacy
                          </li>
                          <li className="overview-list">
                            Replay history of events, relationships, and
                            interactions across all entities on the timeline
                            represented by data.
                          </li>
                          <li className="overview-list">
                            Leverage intuitive auto-clustering of entities and
                            interactions to manage large data volumes
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" data-aos="fade-up">
                  <div className="product-neroml-container">
                    <div class="product-text-wrapper">
                      <div class="overview">
                        <h3 className="overview-heading">
                          <strong>Single unified data model</strong>
                        </h3>
                        <ul className="overview-text-list">
                          <li className="overview-list">
                            Integrate and visualise data from multiple sources,
                            including both structured and unstructured data.
                          </li>
                          <li className="overview-list">
                            Update unified data model in near real-time with
                            secure and scalable data ingest pipelines
                          </li>
                          <li className="overview-list">
                            Explore raw or enriched data linked to all entities
                            across the single data model, enabling deep and
                            thorough analysis
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="product-image-wrapper">
                      <img src={secure} class="img-fluid" alt={secure} />
                    </div>
                  </div>
                </div>

                <div class="row" data-aos="fade-up">
                  <div className="product-neroml-container">
                    <div class="product-image-wrapper">
                      <img src={security} class="img-fluid" alt={security} />
                    </div>
                    <div class="product-text-wrapper">
                      <div class="overview">
                        <h3 className="overview-heading">
                          <strong>AI-powered pattern identification</strong>
                        </h3>
                        <ul className="overview-text-list">
                          <li className="overview-list">
                            Leverage AI-powered pattern mining across entities
                            and interactions to reveal next best actions.
                          </li>
                          <li className="overview-list">
                            Hone in on signals of relevance while exploring new
                            hypotheticals.
                          </li>
                          <li className="overview-list">
                            Flag issues for priority review and collaborate
                            follow-ups with other users.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" data-aos="fade-up">
                  <div className="product-neroml-container">
                    <div class="product-text-wrapper">
                      <div class="overview">
                        <h3 className="overview-heading">
                          <strong>Deep investigative analysis</strong>
                        </h3>
                        <ul className="overview-text-list">
                          <li className="overview-list">
                            Start with one entity and build an investigation
                            case through visual analysis and recommended data to
                            review
                          </li>
                          <li className="overview-list">
                            Explore all interaction types for any entity or
                            focus only on a subset.
                          </li>
                          <li className="overview-list">
                            Undo, redo, or jump to any investigative step via an
                            activity log
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="product-image-wrapper">
                      <img
                        src={securelyLocked}
                        class="img-fluid"
                        alt={securelyLocked}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="container-height section-bg"
          data-aos="fade-up"
          date-aos-delay="200"
        >
          <div className="container">
            <div className="container-margin-sides">
              <div className="row">
                <h4 className="col-md-12 col-lg-12 demo-h4">
                  Sign up for a Free Demo
                </h4>
              </div>

              <div className="row button-spacing">
                <button class="button-28" value="button" onClick={() => navigate("/NERoML-free-demo")}>
                  NERoML - Free Demo
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default NERoMLProductPage;
