import React from "react";
import Navigation from "../components/home-page/NavigationSection";
import FinancialService from "../components/industries/finance/FinancialServicesPage";
import MinimalFooter from "../components/footer-section/MinimalFooter";

const FinancialServices = () => {
  return (
    <div>
      <Navigation />
      <FinancialService />
      <MinimalFooter />
    </div>
  );
};

export default FinancialServices;
