import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const MinimalFooter = () => {
  return (
    <Fragment>
      <footer id="footer">
        <div className="footer-mini-top">
          <div className="container">
            <div className="container-margin-sides">
                <div className="footer-links-section">
                  <ul id="footer-unordered-list">
                    <Link to="/" className="footer-link">
                      <p>Home</p>
                    </Link>
                    <Link to="/contact-us" className="footer-link">
                      <p>Get Started</p>
                    </Link>
                    <Link to="/privacy-policy" className="footer-link">
                      <p>Privacy Policy</p>
                    </Link>
                    <Link to="/terms-of-use" className="footer-link">
                      <p>Terms of Use</p>
                    </Link>
                    <Link to="/code-of-conduct" className="footer-link">
                      <p>Code of Conduct</p>
                    </Link>
                    <Link to="/sitemap" className="footer-link">
                      <p>Site Map</p>
                    </Link>
                  </ul>
              </div>
            </div>
          </div>

          <div className="copyright">
            2023 &copy; Copyright{" "}
            <strong>
              <span>Dark Pools</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </footer>
      <a href="#" className="back-to-top">
        <i className="icofont-simple-up"></i>
      </a>
    </Fragment>
  );
};

export default MinimalFooter;
