import React from "react";
import Navigation from "../components/home-page/NavigationSection";
import RetailServicePage from "../components/industries/retail/RetailServicesPage";
import MinimalFooter from "../components/footer-section/MinimalFooter";

const Retail = () => {
  return (
    <div>
      <Navigation />
      <RetailServicePage />
      <MinimalFooter />
    </div>
  );
};

export default Retail;
