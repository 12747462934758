import React from "react";
import Navigation from "../components/home-page/NavigationSection";
import IndustryForm from "../components/industries/IndustryForm"
import MinimalFooter from "../components/footer-section/MinimalFooter";

const IndustryDemoForm = () => {
  return (
    <div>
      <Navigation />
      <IndustryForm />
      <MinimalFooter />
    </div>
  );
};

export default IndustryDemoForm;
