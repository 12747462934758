import React from "react";
import Navigation from "../components/home-page/NavigationSection";
import HyddepPageProduct  from "../components/products/hyddep-page/HyddepPageProduct";
import MinimalFooter from "../components/footer-section/MinimalFooter";

const HyDDEP_Products = () => {
  return (
    <div>
      <Navigation />
      <HyddepPageProduct />
      <MinimalFooter />
    </div>
  );
};

export default HyDDEP_Products;
