import React from "react";
import Navigation from "../components/home-page/NavigationSection";
import GovernmentPage from "../components/industries/government/GovernmentServicesPage";
import MinimalFooter from "../components/footer-section/MinimalFooter";

const Government = () => {
  return (
    <div>
      <Navigation />
      <GovernmentPage />
      <MinimalFooter />
    </div>
  );
};

export default Government;
