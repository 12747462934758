import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import NERoML_Header from "../../assets/videos/NERoML_Header.mp4";
import NERoML_image from "../../assets/images/main-logos/NERoML.png";
import "../styles.css";

const NERoMLPage = () => {
  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <Link to="/NERoML" className="list-item sidebar-link">
            Overview
          </Link>
          <Link to="/NERoML-products" className="list-item sidebar-link">
            Product
          </Link>
          <Link to="/NERoML-free-demo" className="list-item sidebar-link">
            Free Demo
          </Link>
        </ul>
      </div>

      <section
        id="hero"
        className="d-flex justify-content-center align-items-center"
      >
        <video autoPlay muted loop>
          <source src={NERoML_Header} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-ride="carousel"
        >
          <div className="container-margin-sides">
            <div className="carousel-item active">
              <div className="carousel-container">
                <img
                  src={NERoML_image}
                  alt={NERoML_image}
                  className="main-logo"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <main id="main-form">
        <section className="about" data-aos="fade-up">
          <div className="container">
            <div className="container-margin-sides">
              <div className="row">
                <div className="overview">
                  <p className="overview-text">
                    NERoML® enables advanced network and temporal analytics
                    through a rich visual and interactive workflow-enabled
                    interface which allows users to visualise, understand and
                    leverage the hidden relationships between data entities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about" data-aos="fade-up">
          <div className="container">
            <div className="container-margin-sides">
              <div className="row">
                <div className="overview">
                  <p className="overview-text">
                    NERoML® also adds data geospatial science to geocode
                    locations of interest on our visualisation dashboard in a
                    consolidated. Large volumes of data with billions of edges
                    and vertices can be visualised on a single view with
                    drill-in capabilities on nodes of interest.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default NERoMLPage;
