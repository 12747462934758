import React from "react";
import AI_Learning_Process from "./assets/videos/ai-learning-and-analytics-process.mp4";
import Building_Models from "./assets/images/news/MIT-Decipherable-Features-01-press.jpg";
import Keep_data_safe from "./assets/images/news/Keep-data-safe.jpg";
import Entropy_estimators_0 from "./assets/images/news/Entropy-estimators_0.jpg";
import Baskin_MachineLearn_0 from "./assets/images/news/Baskin-MachineLearn_0.jpg";
import Contrastive_Learning from "./assets/images/news/Contrastive-Learning.jpg";

const News = () => {
  return (
    <div>
      <section
        id="hero"
        className="d-flex justify-cntent-center align-items-center"
      >
        <video className="video-mg" autoPlay muted loop>
          <source src={AI_Learning_Process} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="carousel carousel-fade container"
          data-ride="carousel"
        >
          <div className="container-margin-sides">
            <div className="carousel-item active">
              <div className="carousel-container">
                <p
                  className="animated fadeInUp main-text-resources"
                  data-aos="fade-up"
                >
                  News
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-margin-sides">
          <div className="card-wrapper">
            <div class="card" data-aos="fade-down">
              <div class="card-container">
                <p>
                  <strong>June 30, 2022</strong>
                </p>
                <h4 className="sub-main-title">
                  <strong>
                    <a
                      href="https://news.mit.edu/2022/explainability-machine-learning-0630"
                      target="_blank" className="select_bb"
                    >
                      Building explainability into the components of
                      machine-learning models
                    </a>
                  </strong>
                </h4>
              </div>
              <div className="center-news-image">
                <img src={Building_Models} alt="Avatar" />
              </div>
            </div>
            <div class="card" data-aos="fade-down">
              <div class="card-container">
                <p>
                  <strong>June 9, 2022</strong>
                </p>
                <h4 className="sub-main-title">
                  <strong>
                    <a
                      href="https://news.mit.edu/2022/side-channel-attacks-detection-0609"
                      target="_blank"
                      className="select_bb"
                    >
                      Keeping web-browsing data safe from hackers
                    </a>
                  </strong>
                </h4>
              </div>
              <div className="center-news-image">
                <img src={Keep_data_safe} alt="Avatar" />
              </div>
            </div>
            <div class="card" data-aos="fade-down">
              <div class="card-container">
                <p>
                  <strong>April 25, 2022</strong>
                </p>
                <h4 className="sub-main-title">
                  <strong>
                    <a
                      href="https://news.mit.edu/2022/estimating-informativeness-data-0425"
                      target="_blank"
                      className="select_bb"
                    >
                      Estimating the informativeness of data{" "}
                    </a>
                  </strong>
                </h4>
              </div>
              <div className="center-news-image">
                <img src={Entropy_estimators_0} alt="Avatar" />
              </div>
            </div>
            <div class="card" data-aos="fade-down">
              <div class="card-container">
                <p>
                  <strong>March 1, 2022</strong>
                </p>
                <h4 className="sub-main-title">
                  <strong>
                    <a
                      href="https://news.mit.edu/2022/artificial-intelligence-anomalies-data-0225"
                      target="_blank"
                      className="select_bb"
                    >
                      Study examines how machine learning boosts manufacturing
                    </a>
                  </strong>
                </h4>
              </div>
              <div className="center-news-image">
                <img src={Baskin_MachineLearn_0} alt="Avatar" />
              </div>
            </div>
            <div class="card" data-aos="fade-down">
              <div class="card-container">
                <p>
                  <strong>November 2, 2021</strong>
                </p>
                <h4 className="sub-main-title">
                  <strong>
                    <a
                      href="https://news.mit.edu/2021/shortcut-artificial-intelligence-1102"
                      target="_blank"
                      className="select_bb"
                    >
                      Avoiding shortcut solutions in artificial intelligence
                    </a>
                  </strong>
                </h4>
              </div>
              <div className="center-news-image">
                <img src={Contrastive_Learning} alt="Avatar" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default News;
