import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import FreeDemoForm from "../products/FreeDemoForm";
import "../products/styles.css";

const AIMLoWDemo = () => {
  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <Link to="/AIMLoW" className="list-item sidebar-link">
            Overview
          </Link>
          <Link to="/AIMLoW-products" className="list-item sidebar-link">
            Product
          </Link>
          <Link to="/AIMLoW-free-demo" className="list-item sidebar-link">
            Free Demo
          </Link>
        </ul>
      </div>

      <main id="main-form">
        <section className="contact">
          <div className="container demo-form center-form">
            <div className="">
              <FreeDemoForm />
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default AIMLoWDemo;
