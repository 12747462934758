import React from "react";

const PrivacyPolicy = () => {
  return (
    <main id="main">
      <div className="container">
        <section>
          <div className="container-margin-sides">
            <h1 className="title-head" data-aos="fade-down">Dark Pools Privacy Policy</h1>
            <p className="terms-and-policy-text" data-aos="fade-up">
              Dark Pools is committed to the protection of the privacy rights of
              individuals. This Privacy Policy (
              <strong>"Dark Pools Privacy Policy</strong>") explains how Dark
              Pools handles information relating to an identified or
              identifiable individual ("Personal Information") that is provided
              to Dark Pools or that Dark Pools collects when you visit www.Dark
              Pools.ai (the "Site"). More specifically, this Privacy Policy
              informs you as to the categories of Personal Information that we
              collect or process, why such Personal Information is processed,
              how it is used and what rights you have. This Privacy Policy does
              not apply to Personal Information collected on any third-party
              site or application (including advertising) that may link to or be
              accessible from our websites. We are not responsible for the
              privacy policies or data collection, use and disclosure practices
              of those sites. We encourage you to review the privacy policies of
              each site you visit.
            </p>
            <p className="terms-and-policy-text" data-aos="fade-up">
              The terms "you," "your," and "yours" refer to anyone utilizing the
              Site. The terms "Dark Pools," "we," "us," and "our" refer to
              DarkPools.ai, Inc. and its subsidiaries and affiliates. By using
              the Site, you consent to the practices set forth in this Privacy
              Policy. We may periodically make changes to this Privacy Policy
              that we will include on this page. It is your responsibility to
              review this Privacy Policy frequently and remain informed about
              any changes.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">
              <strong>1. How and What Personal Information Is Collected</strong>
            </h2>
            <p className="sub-main-title" data-aos="fade-up">
              <strong>1.1 ACCOUNT CREATION</strong>
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              Use of the Site; Cookies. Dark Pools collects Personal Information
              that you voluntarily provide us by completing and submitting forms
              on this Site, such as on the "Contact Us" page on the Site. This
              Personal Information may include information such as your name,
              email address, telephone number, company name and size, job title
              and the reason why you are contacting us. You may also use the
              site to apply for a job with us, in which case you will provide us
              Personal Information relevant to your job application.
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              The Site also automatically collects Personal Information through
              the use of cookies or similar technologies to facilitate and
              enhance your use of the Site and track usage patterns. Cookies are
              commonly used small files that are placed on your device. For
              example, we collect information on the pages you have visited,
              your referring URL or IP address, the time of your visit and
              related session information, your requested page, browser type,
              USR agent, status of request, number of bytes in the response, and
              query string. The cookies that the Site uses generally fall under
              the following categories:
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              a. Essential Cookies. Essential cookies facilitate your use and
              navigation of the Site. Essential cookies include session cookies,
              which are temporary cookies that remain in the cookie file of your
              browser until you close the browser.
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              b. Analytics Cookies. We and our service providers use analytics
              cookies that collect aggregated and / or anonymous information to
              analyze the performance of the Site and how it is used. For
              example, analytics cookies show us which areas of the Site are
              most frequently visited. An example of a third-party analytics
              cookie is the Google Analytics cookie. Users may obtain
              information on how to opt out of Google Analytics cookies at the
              following URL: https://policies.google.com/technologies/ads.
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              c. Third Party Marketing Cookies. These cookies collect
              information on your activities on the Site and other sites to
              display targeted marketing based on your interests.
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              Some browsers offer a "do not track" ("DNT") option. Because no
              common industry or legal standard for DNT has been adopted by
              industry groups, technology companies or regulators, we do not
              respond to DNT signals. We will make efforts to continue to
              monitor developments around DNT browser technology and the
              implementation of a standard. However, you may elect not to accept
              cookies by changing the designated settings on your web browser.
              Please note that some features of our Site may not function
              without the essential cookies.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">
              <strong>2. How Information Is Used</strong>
            </h2>
            <p className="main-content-paragraph" data-aos="fade-up">
              We may use the Personal Information in one or more of the
              following ways:
            </p>
            <ul className="unordered-list" data-aos="fade-up">
              <li className="main-content-paragraph">
                Sending information or materials you request
              </li>
              <li className="main-content-paragraph">
                Responding to your questions and concerns posted via the Site
              </li>
              <li className="main-content-paragraph">
                Operating and improving the Site
              </li>
              <li className="main-content-paragraph">
                Improving our products and services and our marketing efforts
              </li>
              <li className="main-content-paragraph">
                Conducting research and analysis
              </li>
              <li className="main-content-paragraph">
                Processing and delivering contest entries and rewards
              </li>
              <li className="main-content-paragraph">
                Evaluating your job application if you have submitted it through
                the Site
              </li>
              <li className="main-content-paragraph">
                Linking or combining your information with other information we
                may receive from third parties to help better serve you.
              </li>
            </ul>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">3. Legal Basis for the Processing</h2>
            <p className="main-content-paragraph" data-aos="fade-up">
              We will only process your Personal Information if we have one of
              the following legal bases to do so:
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              a. the processing is necessary to comply with our legal or
              regulatory obligations, such as tax reporting or regulatory
              requirements
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              b. we have obtained your prior consent for processing your
              Personal Information for a specific purpose, such as evaluating
              your job application
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              c. the processing is necessary for the legitimate interests of
              Dark Pools, which are not overridden by your interests or
              fundamental rights and freedoms.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">
              4. Duration of processing of Personal Data.
            </h2>
            <p className="main-content-paragraph" data-aos="fade-up">
              When Dark Pools is processing your Personal Information pursuant
              to any of the legal bases set out in section 3 above, Dark Pools
              will store your Personal Information only for as long as is
              required to fulfil the purpose for which it was collected.
              However, if legal basis b. (consent) applies, Dark Pools will stop
              processing your Personal Information upon notification of your
              withdrawal of consent for such processing. However, where Dark
              Pools are required by law to retain your Personal Information
              longer or where your Personal Information is required for Dark
              Pools to assert or defend against legal claims, Dark Pools will
              retain your Personal Information until the end of the relevant
              retention period or until the claims in question have been
              settled.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">
              5. Sharing and Disclosure of Information
            </h2>
            <p className="main-content-paragraph" data-aos="fade-up">
              Dark Pools does not rent or sell your Personal Information. Dark
              Pools does not share your Personal Information with third parties
              other than agents, contractors, and service providers who support
              our business and who are bound by contractual obligations to keep
              your Personal Information confidential and use it only for the
              purposes of providing services to us. However, we may disclose
              Personal Information if we believe it is necessary or appropriate
              under applicable law (i) to comply with any court order, law or
              legal process, including to respond to any government or
              regulatory request, (ii) to enforce or apply our Terms of Use or
              any other agreements concerning our Site or (iii) to protect and
              defend the rights or property of Dark Pools. Dark Pools may
              transfer your information to a buyer or other successor in the
              event of any reorganization, merger, sale, divestiture,
              restructuring, reorganization, dissolution or other disposition of
              all or any portion of Dark Pools assets.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">
              6. What are Your Rights and How Can You Exercise Them?
            </h2>
            <p className="main-content-paragraph" data-aos="fade-up">
              You may opt out of receiving promotional emails from us by
              following the instructions in those emails. If you opt out of
              receiving promotional emails, we may still send you
              non-promotional emails, such as emails about our ongoing business
              relations (if any).
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              You have a right to access and to obtain a copy of your Personal
              Information processed by Dark Pools. If you believe that any of
              the Personal Information we hold about you is incorrect or
              incomplete, you may request that it be corrected.
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              You also have the right to:
            </p>
            <ul className="unordered-list" data-aos="fade-up">
              <li className="main-content-paragraph">
                object to the processing of your Personal Information
              </li>
              <li className="main-content-paragraph">
                request the erasure of your Personal Information
              </li>
              <li className="main-content-paragraph">
                request restriction of the processing of your Personal
                Information, and/or
              </li>
              <li className="main-content-paragraph">
                withdraw your consent where Dark Pools obtained your consent to
                process Personal Information.
              </li>
            </ul>
            <p className="main-content-paragraph" data-aos="fade-up">
              We will honour such request, withdrawal or objection as required
              under applicable data protection rules, but you should bear in
              mind that these rights are not absolute; they do not always apply
              and may be subject to exceptions.
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              To exercise your rights, you may send an email to
              privacy@Darkpools.ai. If you are not satisfied with our response,
              you have the right to make a complaint to the data protection
              authority in the jurisdiction where you live or work. The contact
              details of each Data Protection Authority can be found at the
              following website: https://inforegulator.org.za/
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">7. Information Security</h2>
            <p className="main-content-paragraph" data-aos="fade-up">
              Dark Pools takes the security of your Personal Information
              seriously. In order to protect your Personal Information from
              unauthorised access, disclosure, alteration, or destruction, we
              implement technical and organizational measures that we consider
              appropriate in light of the state of the art, the costs of
              implementation, and the nature, scope, context, and purposes of
              processing as well as the risk of varying likelihood and severity
              for the rights and freedoms of natural persons. However, you
              should be aware that we cannot ensure that your Personal
              Information will never be accessed, disclosed, altered, or
              destroyed in ways not described in this Privacy Policy. By way of
              example, third parties may unlawfully intercept or access
              transmissions or private communications.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">8. International Transfers</h2>
            <p className="main-content-paragraph" data-aos="fade-up">
              Dark Pools is headquartered in South Africa and has operations and
              service providers in South Africa and throughout the world. As
              such, we and our service providers may transfer your Personal
              Information to, or access it in, jurisdictions that may not
              provide levels of data protection equivalent to those in your home
              country. Whenever Personal Information is transferred out of the
              EEA, the transfer is based on the Standard Contractual Clauses
              (according to EU Commission Decision 87/2010/EC or any future
              replacement) or adherence to the Privacy Shield (when applicable
              for transfers to South Africa). Regardless of where you live or
              where you provide information to us, you authorise Dark Pools to
              use your information worldwide and to export your information to
              South Africa and other countries.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">9. Children</h2>
            <p className="main-content-paragraph" data-aos="fade-up">
              This Site is not intended for or directed to persons under the age
              of 13. Any person who provides their information to Dark Pools
              through any part of the Site represents to Dark Pools that they
              are 13 years of age or older.
            </p>
          </div>
        </section>
        <section>
          <div className="container-margin-sides">
            <h2 className="main-title" data-aos="fade-right">10. Questions and Concerns</h2>
            <p className="main-content-paragraph" data-aos="fade-up">
              If you have any questions or concerns regarding the way we collect
              or handle your information, please contact us at
              privacy@Darkpools.ai. Our physical mailing address is:
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">DarkPools.ai, Inc.</p>
            <p className="main-content-paragraph" data-aos="fade-up">
              6 North Road, Dunkeld West.
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              Johannesburg, South Africa 2196
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              We will take every privacy concern seriously and will assess it in
              a reasonably timely manner.
            </p>
            <p className="main-content-paragraph" data-aos="fade-up">
              Last updated on: March 15, 2023
            </p>
          </div>
        </section>
      </div>
    </main>
  );
};

export default PrivacyPolicy;
