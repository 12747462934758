import React from "react";
import Navigation from "../components/home-page/NavigationSection";
import AIMLoWDemo from "../components/products/aimlow-page/AIMLoWDemo"
import MinimalFooter from "../components/footer-section/MinimalFooter";

const AIMLoW_Freedemo = () => {
  return (
    <div>
      <Navigation />
      <AIMLoWDemo />
      <MinimalFooter />
    </div>
  );
};

export default AIMLoW_Freedemo;
