import React from "react";
import Navigation from "../components/home-page/NavigationSection";
import NERoMLPage from "../components/products/neroml-page/NERoMLPage";
import MinimalFooter from "../components/footer-section/MinimalFooter";

const NERoML = () => {
  return (
    <div>
      <Navigation />
      <NERoMLPage />
      <MinimalFooter />
    </div>
  );
};

export default NERoML;
