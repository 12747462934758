import React from "react";
import ContactFormSales from "../components/contact-page/ContactFormSales";
import Navigation from "../components/home-page/NavigationSection";
import MinimalFooter from "../components/footer-section/MinimalFooter";

const ContactSale = () => {
  return (
    <div>
      <Navigation />
      <ContactFormSales />
      <MinimalFooter />
    </div>
  );
};

export default ContactSale;
