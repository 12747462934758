import React from "react";
import Navigation from "./components/home-page/NavigationSection";
import CustomersPage from "./components/CustomersPage";
import MinimalFooter from "./components/footer-section/MinimalFooter";
import "./App.css";

const Customers = () => {
  return (
    <div>
      <Navigation />
      <CustomersPage />
      <MinimalFooter />
    </div>
  );
};

export default Customers;
