import React from "react";
import Navigation from "../components/home-page/NavigationSection";
import GovernmentProductPage from "../components/industries/government/GovernmentProductPage";
import MinimalFooter from "../components/footer-section/MinimalFooter";

const GovernmentProducts = () => {
  return (
    <div>
      <Navigation />
        <GovernmentProductPage />
      <MinimalFooter />
    </div>
  );
};

export default GovernmentProducts;
