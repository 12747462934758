import React from "react";
import Navigation from "../components/home-page/NavigationSection";
import  NERoMLProductPage  from "../components/products/neroml-page/NERoMLProductPage";
import MinimalFooter from "../components/footer-section/MinimalFooter";

const NERoML_Products = () => {
  return (
    <div>
      <Navigation />
      <NERoMLProductPage />
      <MinimalFooter />
    </div>
  );
};

export default NERoML_Products;
