import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import routesConfig from "./routes/routes.config";
import "./App.css";

const App = () => {
  return (
    <div id="sub-root">
      <BrowserRouter>
        <Routes>
            {routesConfig.map((route) => {
              const Component = route.component;
              return (
                <Route
                  key={route.name}
                  path={route.path}
                  element={<Component />}
                />
              );
            })}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
