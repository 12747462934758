import React from "react";
import Customer_Header from "./assets/videos/Customer_Header.mp4";
import GicLogo from "./assets/images/gic-logo.png";
import ClientelLogo from "./assets/images/clientele.svg";
import MTNLogo from "./assets/images/mtn_logo_4.svg";

const Customers = () => {
  return (
    <>
      <section
        id="hero"
        className="d-flex justify-content-center align-items-center"
      >
        <video autoPlay muted loop>
          <source src={Customer_Header} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-ride="carousel"
        >
          <div className="container-margin-sides">
            <div className="carousel-item active">
              <div className="carousel-container">
                <h2 className="animated fadeInDown">
                  <span>Our Customers</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section className="services">
          <div className="container">
            <div className="container-margin-sides">
              <div className="row contact-row">
                <div class="logo-image-wrapper" data-aos="fade-up">
                  <img
                    src={GicLogo}
                    alt={GicLogo}
                    class="img-fluid"
                    id="small-image"
                  />
                </div>
                <div
                  class="logo-image-wrapper"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <img
                    src={ClientelLogo}
                    class="img-fluid"
                    alt={ClientelLogo}
                  />
                </div>
                <div
                  class="logo-image-wrapper"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <img src={MTNLogo} class="img-fluid" alt={MTNLogo} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Customers;
