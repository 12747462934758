import React from "react";
import Navigation from "./components/home-page/NavigationSection";
import ContactUs from "./components/ContactUsSection";
import MinimalFooter from "./components/footer-section/MinimalFooter";
import "./App.css";

const ContactUsPage = () => {
  return (
    <div>
      <Navigation />
      <ContactUs />
      <MinimalFooter />
    </div>
  );
};

export default ContactUsPage;
