import React from "react";
import Navigation from "../components/home-page/NavigationSection";
import AimlowProduct from "../components/products/aimlow-page/AimlowProduct";
import MinimalFooter from "../components/footer-section/MinimalFooter";

const AIMLow_Products = () => {
  return (
    <div>
      <Navigation />
      <AimlowProduct />
      <MinimalFooter />
    </div>
  );
};

export default AIMLow_Products;
