import React from "react";
import Navigation from "../components/home-page/NavigationSection";
import NERoMLFreedemoPage from "../components/products/neroml-page/NERoMLDemo";
import MinimalFooter from "../components/footer-section/MinimalFooter";

const NERoML_freedemo = () => {
  return (
    <div>
      <Navigation />
      <NERoMLFreedemoPage />
      <MinimalFooter />
    </div>
  );
};

export default NERoML_freedemo;
