import React from "react";
import Finance from "../assets/images/Dark-Pools imgs/Finance.png";
import Government from "../assets/images/Dark-Pools imgs/Government.png";
import Retail from "../assets/images/Dark-Pools imgs/Retail.png";
import Telecommunication from "../assets/images/Dark-Pools imgs/Telecom.png";
import './style.css';

const Industries = () => {
  return (
    <ul className="unordered">
      <li className="list">
        <a href="./financial-services">
          <img
            src={Finance}
            alt={"Landing Banking"}
            width="200px"
            className="animated fadeInDown"
            style={{animationDelay: "1s"}}
          />
          <p className="animated fadeInUp" style={{animationDelay: "1s"}}><strong>Financial Services</strong></p>
        </a>
      </li>
      <hr/>
      <li className="list">
        <a href="./government">
          <img
            src={Government}
            alt={"Landing Aerospace Defense"}
            width="200px"
            className="animated fadeInDown"
            style={{animationDelay: "1.2s"}}
          />
          <p className="animated fadeInUp" style={{animationDelay: "1.2s"}}><strong>Government</strong></p>
        </a>
      </li>
      <hr/>
      <li className="list">
        <a href="./retail">
          <img
            src={Retail}
            alt={"Landing Retail"}
            width="200px"
            className="animated fadeInDown"
            style={{animationDelay: "1.4s"}}
          />
          <p className="animated fadeInUp" style={{animationDelay: "1.4s"}}><strong>Retail</strong></p>
        </a>
      </li>
      <hr/>
      <li className="list">
        <a href="./telecom">
          <img
            src={Telecommunication}
            alt={"Landing Telecommunication"}
            width="200px"
            className="animated fadeInDown"
            style={{animationDelay: "1.6s"}}
          />
          <p className="animated fadeInUp" style={{animationDelay: "1.6s"}}><strong>Telecommunication</strong></p>
        </a>
      </li>
    </ul>
  );
};

export default Industries;
