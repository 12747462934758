import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

const Footer = () => {
  return (
    <>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="container-margin-sides">
              <div className="footer-solution-content">
                <div className="footer-links-section">
                  <h4 className="footer-center">Company</h4>
                  <ul className="footer-list">
                    <Link to="/" className="footer-link">
                      <p>Home</p>
                    </Link>
                    <Link to="company" className="footer-link">
                      <p>About us</p>
                    </Link>
                    <Link to="HyDDEP" className="footer-link">
                      <p>Services</p>
                    </Link>
                  </ul>
                </div>

                <div className="footer-links-section">
                  <h4 className="footer-center">Products</h4>
                  <ul className="footer-list">
                    <Link to="/HyDDEP" className="footer-link">
                      <p>HyDDEP</p>
                    </Link>
                    <Link to="/AIMLow" className="footer-link">
                      <p>AIMLow</p>
                    </Link>
                    <Link to="/NERoML" className="footer-link">
                      <p>NERoML</p>
                    </Link>
                  </ul>
                </div>

                <div className="footer-links-section ">
                  <h4 className="footer-center">Industries</h4>
                  <ul className="footer-list">
                    <Link to="/financial-services" className="footer-link">
                      <p>Financial Services</p>
                    </Link>
                    <Link to="/government" className="footer-link">
                      <p>Government</p>
                    </Link>
                    <Link to="/retail" className="footer-link">
                      <p>Retail</p>
                    </Link>
                    <Link to="/telecommunication" className="footer-link">
                      <p>Telecommunication</p>
                    </Link>
                  </ul>
                </div>

                <div className="footer-links-section ">
                  <h4 className="footer-center">Help</h4>
                  <ul className="footer-list">
                    <Link to="/contact-us" className="footer-link">
                      <p>Get Started</p>
                    </Link>
                    <Link to="/news" className="footer-link">
                      <p>News</p>
                    </Link>
                    <Link to="/resources" className="footer-link">
                      <p>Resources</p>
                    </Link>
                    <Link to="/#" className="footer-link">
                      <p>Careers</p>
                    </Link>
                  </ul>
                </div>

                <div className="footer-links-section ">
                  <h4 className="footer-center">Other</h4>
                  <ul className="footer-list">
                    <Link to="/privacy-policy" className="footer-link">
                      <p>Privacy Policy</p>
                    </Link>
                    <Link to="/terms-of-use" className="footer-link">
                      <p>Terms of Use</p>
                    </Link>
                    <Link to="/code-of-conduct" className="footer-link">
                      <p>Code of Conduct</p>
                    </Link>
                    <Link to="/sitemap" className="footer-link">
                      <p>Site Map</p>
                    </Link>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="copyright">
            2023 &copy; Copyright{" "}
            <strong>
              <span>Dark Pools</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </footer>
      <a href="#" className="back-to-top">
        <i className="icofont-simple-up"></i>
      </a>
    </>
  );
};

export default Footer;
