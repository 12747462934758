import React from "react";
import Navigation from "../components/home-page/NavigationSection";
import HYDDEPDemo from "../components/products/hyddep-page/HYDDEPDemo";
import MinimalFooter from "../components/footer-section/MinimalFooter";

const HYDDEP_freedemo = () => {
  return (
    <div>
      <Navigation />
      <HYDDEPDemo />
      <MinimalFooter />
    </div>
  );
};

export default HYDDEP_freedemo;
