import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import video from "../../assets/videos/Industries_Header.mp4";
import image2 from "../../assets/images/DarkPools white transparent images/Retail-white.png";
import { keyAreaData } from "./key-areas";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IconContext } from "react-icons";
import "./retail-styles.css";
import "../styles.css";

const RetailServicePage = () => {
  const [keyPoints, setKeyPoints] = useState(keyAreaData[0].keyIssues);

  const openBlock = (e, data, i) => {
    let issueBlock = document.getElementsByClassName("issue-block");
    for (i = 0; i < issueBlock.length; i++) {
      issueBlock[i].className = issueBlock[i].className.replace(" active", " ");
    }
    e.currentTarget.className += " active";
    setKeyPoints(data.keyIssues);
  };

  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <Link to="/retail" className="list-item sidebar-link">
            Overview
          </Link>
          <Link to="/retail-products" className="list-item sidebar-link">
            Products
          </Link>
          <Link to="/industry-demo-form" className="list-item sidebar-link">
            Free Demo
          </Link>
        </ul>
      </div>

      <section
        id="hero"
        className="d-flex justify-cntent-center align-items-center"
      >
        <video autoPlay muted loop>
          <source src={video} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="carousel carousel-fade container"
          data-ride="carousel"
        >
          <div className="container-margin-sides">
            <div className="carousel-item active">
              <div className="">
                <div></div>
                <h2 className="darkpools-heading  animated fadeInDown">
                  <span>Dark Pools</span>
                </h2>
                <p className="animated fadeInUp main-text">Retail Services</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section className="about-section">
          <div className="container-margin-sides">
            <div className="block">
              <span id="overview-heading">
                Dark Pools Addresses Key Issues Across the Retail Value Chain
              </span>
              <p className="overview-text">
                Helping retail organizations use shopper, transactional, and
                category trend data for predictive insights that improve
                transactional volume and customer profitability, minimise supply
                chain costs, and optimise store operations.
              </p>
              <div className="inside-content-wrapper">
                <p className="overview-text">
                  Dark Pools Reduces inventory holding costs, improve cash flow
                  and supply chain visibility, and increase the productivity of
                  inventory analysts. Applying advanced machine learning to
                  analyze variability in demand, supplier delivery times, and
                  quality issues to build real-time recommendations and
                  monitoring, so users can set optimization by confidence level
                  and receive real-time notifications and root-cause analysis.
                </p>
                <div className="image-wrapper">
                  <img src={image2} alt={image2} className="main-images" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="middle-section">
          <div id="issue-table" className="container-margin-sides">
            <div className="block">
              <div className="product-panel-block">
                {keyAreaData.map((data, i) => (
                  <span
                    className="issue-block"
                    key={i}
                    onClick={(e) => openBlock(e, data, i)}
                  >
                    {data.title}
                  </span>
                ))}
              </div>
              <div className="retail-panel-content">
                <div className="p-tag">
                  <p>Key Issues</p>
                </div>
                <div className="key-issues-container">
                  {keyPoints.map((keyIssue, i) => (
                    <div className="key-issues" key={i}>
                      <IconContext.Provider
                        value={{
                          color: "green",
                          className: "global-class-name",
                        }}
                      >
                        <IoMdCheckmarkCircleOutline />
                      </IconContext.Provider>
                      <span id="spacing">{keyIssue}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default RetailServicePage;
