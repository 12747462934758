import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import video from "../../assets/videos/Industries_Header.mp4";
import network from "../../assets/images/Product-images/Images-1.png";
import server from "../../assets/images/Product-images/Images-3.png";
import aiVision from "../../assets/images/Product-images/AI-Vision.png";
import { keyAreaProducts } from "./key-areas";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IconContext } from "react-icons";
import "./retail-styles.css";
import "../styles.css";

const RetailProductsPage = () => {
  const [keyPoints, setKeyPoints] = useState(keyAreaProducts[0].keyIssues);

  const openBlock = (e, data, i) => {
    let issueBlock = document.getElementsByClassName("issue-block");
    for (i = 0; i < issueBlock.length; i++) {
      issueBlock[i].className = issueBlock[i].className.replace(" active", " ");
    }
    e.currentTarget.className += " active";
    setKeyPoints(data.keyIssues);
  };

  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <Link to="/retail" className="list-item sidebar-link">
            Overview
          </Link>
          <Link to="/retail-products" className="list-item sidebar-link">
            Products
          </Link>
          <Link to="/industry-demo-form" className="list-item sidebar-link">
            Free Demo
          </Link>
        </ul>
      </div>

      <section
        id="hero"
        className="d-flex justify-cntent-center align-items-center"
      >
        <video autoPlay muted loop>
          <source src={video} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="carousel carousel-fade container"
          data-ride="carousel"
        >
          <div className="container-margin-sides">
            <div className="carousel-item active">
              <div className="">
                <div></div>
                <h2 className="darkpools-heading  animated fadeInDown">
                  <span>Dark Pools</span>
                </h2>
                <p className="animated fadeInUp main-text">Retail Products</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section className="about-section">
          <div className="container-margin-sides">
            <div className="block">
              <div className="content-section">
                <span id="overview-heading">Industry Problem</span>
                <p className="overview-text">
                  Enforcement actions and penalties for non-compliance with AML
                  regulations are on the rise. Banks are saddled with
                  increasingly higher costs for transaction monitoring or facing
                  the risks of hefty fines. At the same time bad actors are
                  employing an increasingly diverse set of strategies to stay
                  ahead of regulators and compliance departments. Current
                  rules-based AML software creates a slew of cases that require
                  extensive and manual investigation by compliance officers.
                </p>
              </div>
              <div className="content-section">
                <div className="product-container-industry">
                  <div className="text-box-left">
                    <span id="overview-heading">AIMLow</span>
                    <p className="overview-text">
                      Optimise and automate entire retail operations, inventory,
                      increase sales, reduce working capital and improve
                      customer experience with analytics capabilities and
                      real-time interactive dashboards. Create
                      hyper-personalised customer engagement by pro-actively
                      predicting shifts in customer behaviors and sales trends.
                    </p>
                  </div>
                  <div>
                    <img src={aiVision} alt={aiVision} width="320px" />
                  </div>
                </div>
              </div>
              <div className="content-section">
                <div className="product-container-industry">
                  <div className="text-box-left">
                    <img src={network} alt={network} width="320px" />
                  </div>
                  <div>
                    <span id="overview-heading">NERoML</span>
                    <p className="overview-text">
                      Manage your retail network with a highly powerful ML
                      pattern detection tool that can visualise and uncover
                      patterns that far outstrip the power of a relational
                      databases
                    </p>
                  </div>
                </div>
              </div>
              <div className="content-section">
                <div className="product-container-industry">
                  <div className="text-box-left">
                    <span id="overview-heading">HyDDEP</span>
                    <p className="overview-text">
                      Have strategies for integrating data and generating timely
                      insights is vitally important for retailers. By leveraging
                      customer data and integrating you are capable of building
                      the Retail Intelligence you need to be able to provide the
                      services consumers are expecting.
                    </p>
                  </div>
                  <div>
                    <img src={server} alt={server} width="320px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="middle-section">
          <div id="issue-table" className="container-margin-sides">
            <div className="block">
              <div className="product-panel-block">
                {keyAreaProducts.map((data, i) => (
                  <span
                    className="issue-block"
                    key={i}
                    onClick={(e) => openBlock(e, data, i)}
                  >
                    {data.title}
                  </span>
                ))}
              </div>
              <div className="retail-panel-content">
                <div className="p-tag">
                  <p>Key Issues</p>
                </div>
                <div className="key-issues-container">
                  {keyPoints.map((keyIssue, i) => (
                    <div className="key-issues" key={i}>
                      <IconContext.Provider
                        value={{
                          color: "green",
                          className: "global-class-name",
                        }}
                      >
                        <IoMdCheckmarkCircleOutline />
                      </IconContext.Provider>
                      <span id="spacing">{keyIssue}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default RetailProductsPage;
