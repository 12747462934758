import React from "react";
import AI_video from "./assets/videos/header.mp4";
import Leadership from "./assets/images/company/Leadership.png";
import Customer from "./assets/images/company/Customer.png";
import Products from "./assets/images/company/Products.png";
import Partners from "./assets/images/company/Partners.png";
import Investors from "./assets/images/company/Investors.png";
import Industry from "./assets/images/company/Industry.png";
import Career from "./assets/images/company/Career.png";

const Company = () => {
  return (
    <div>
      <section
        id="hero"
        className="d-flex justify-cntent-center align-items-center"
      >
        <video className="video-mg" autoPlay muted loop>
          <source src={AI_video} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="carousel carousel-fade container"
          data-ride="carousel"
        >
          <div className="container-margin-sides">
            <div className="carousel-item active">
              <div className="carousel-container">
                <p
                  className="animated fadeInUp main-text-resources"
                  data-aos="fade-up"
                >
                  Company
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <section className="about-section">
          <div className="container-margin-sides">
            <div className="block">
              <div className="content-section-company">
                <div className="product-container">
                  <div className="text-box-left">
                    <span id="overview-heading">Leadership</span>
                    <p className="overview-text">
                      Leverages the power of Data, Analytics, Machine Learning ,
                      and AI technologies to uncover hidden relationships from
                      vast troves of data, generate insights, and inform
                      decision making across all levels. Improve accuracy of
                      money laundering case detection and efficiency of
                      investigators and financial crime compliance managers.
                    </p>
                    <div className="learn-more-box">
                      <p>Learn more</p>
                    </div>
                  </div>
                  <div>
                    <img src={Leadership} alt={Leadership} width="128px" />
                  </div>
                </div>
              </div>
              <div className="content-section-company">
                <div className="product-container">
                  <div className="text-box-left">
                    <img src={Customer} alt={Customer} width="128px" />
                  </div>
                  <div>
                    <span id="overview-heading">Customers</span>
                    <p className="overview-text">
                      Enhance your financial detection patterns with machine
                      learning, data visualization on a sophisticated Network
                      visualization platform and uncovers difficult-to-detect
                      patterns that far outstrip the power of a relational
                      database.
                    </p>
                    <div className="learn-more-box">
                      <p>Learn more</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-section-company">
                <div className="product-container">
                  <div className="text-box-left">
                    <span id="overview-heading">Partners</span>
                    <p className="overview-text">
                      Enabling you to rationalise, integrate, consolidate, and
                      seamlessly share volumes of data across the organization
                      through a synchronised platform. It enables financial
                      institutes to effectively manage and analyse data and
                      extract trusted insights to expand market share.
                    </p>
                    <div className="learn-more-box">
                      <p>Learn more</p>
                    </div>
                  </div>
                  <div>
                    <img src={Partners} alt={Partners} width="128px" />
                  </div>
                </div>
              </div>
              <div className="content-section-company">
                <div className="product-container">
                  <div className="text-box-left">
                    <img src={Investors} alt={Investors} width="128px" />
                  </div>
                  <div>
                    <span id="overview-heading">Investors Relations</span>
                    <p className="overview-text">
                      Enhance your financial detection patterns with machine
                      learning, data visualization on a sophisticated Network
                      visualization platform and uncovers difficult-to-detect
                      patterns that far outstrip the power of a relational
                      database.
                    </p>
                    <div className="learn-more-box">
                      <p>Learn more</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-section-company">
                <div className="product-container">
                  <div className="text-box-left">
                    <span id="overview-heading">Careers</span>
                    <p className="overview-text">
                      Enabling you to rationalise, integrate, consolidate, and
                      seamlessly share volumes of data across the organization
                      through a synchronised platform. It enables financial
                      institutes to effectively manage and analyse data and
                      extract trusted insights to expand market share.
                    </p>
                    <div className="learn-more-box">
                      <p>Learn more</p>
                    </div>
                  </div>
                  <div>
                    <img src={Career} alt={Career} width="128px" />
                  </div>
                </div>
              </div>
              <div className="content-section-company">
                <div className="product-container">
                  <div className="text-box-left">
                    <img src={Products} alt={Products} width="128px" />
                  </div>
                  <div>
                    <span id="overview-heading">Products</span>
                    <p className="overview-text">
                      Enhance your financial detection patterns with machine
                      learning, data visualization on a sophisticated Network
                      visualization platform and uncovers difficult-to-detect
                      patterns that far outstrip the power of a relational
                      database.
                    </p>
                    <div className="learn-more-box">
                      <p>Learn more</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-section-company">
                <div className="product-container">
                  <div className="text-box-left">
                    <span id="overview-heading">Industries</span>
                    <p className="overview-text">
                      Enabling you to rationalise, integrate, consolidate, and
                      seamlessly share volumes of data across the organization
                      through a synchronised platform. It enables financial
                      institutes to effectively manage and analyse data and
                      extract trusted insights to expand market share.
                    </p>
                    <div className="learn-more-box">
                      <p>Learn more</p>
                    </div>
                  </div>
                  <div>
                    <img src={Industry} alt={Industry} width="128px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Company;
