import React from "react";
import MinimalFooter from "./components/footer-section/MinimalFooter";
import Navigation from "./components/home-page/NavigationSection";
import News from "./components/News";

const NewsPage = () => {
  return (
    <div>
      <Navigation />
      <News />
      <MinimalFooter />
    </div>
  );
};

export default NewsPage;
