import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineArrowRight, AiOutlineSearch } from "react-icons/ai";
import { IconContext } from "react-icons";
import logo from "../assets/images/darkpools-logo.png";
import Product from "./ProductSection";
import Industries from "./IndustriesSection";
import "./style.css";

const Navigation = () => {
  const [openListProducts, setOpenListProducts] = useState(false);
  const [openListIndustries, setOpenListIndustries] = useState(false);
  const [openBtn, setOpenBtn] = useState(false);

  const handleOpenListProducts = (event) => {
    event.preventDefault();
    !openListProducts ? setOpenListProducts(true) : setOpenListProducts(false);
  };

  const handleOpenListIndustries = (event) => {
    event.preventDefault();
    !openListIndustries
      ? setOpenListIndustries(true)
      : setOpenListIndustries(false);
  };

  const handleOpenBtn = () => {
    setOpenBtn(true);
  };

  const handleCloseBtn = () => {
    setOpenBtn(false);
  };
  return (
    <header className="header">
      <div className="logo">
        <a href="/">
          <img src={logo} alt={"logo"} width="40px" />
        </a>
      </div>
      <nav className="navbar-menu">
        <div className="wrapper">
          <ul>
            <li className="drop-down-link">
              <a href="#">Products</a>
              <Product />
            </li>
            <li className="drop-down-link">
              <a href="#">Industries</a>
              <Industries />
            </li>
            <li className="drop-down-link">
              <a href="./customers">Customer</a>
            </li>
            <li className="drop-down-link">
              <a href="./resources">Resources</a>
            </li>
            <li className="drop-down-link">
              <a href="./news">News</a>
            </li>
            <li className="drop-down-link">
              <a href="./company">Company</a>
            </li>
          </ul>
        </div>
        <div className="icon-wrapper">
          <IconContext.Provider
            value={{ color: "white", className: "global-class-name" }}
          >
            <div>
              <AiOutlineSearch />
            </div>
          </IconContext.Provider>
          {/* <input type="text" placeholder="Search.." /> */}
        </div>
      </nav>
      <div className="contact-button-wrapper">
        <div className="contact-button">
          <Link to="/contact-us" id="anchor-color">
            Contact Us
          </Link>
        </div>
        <div className="media-container">
          <div className="social-media-links">
            <a
              href="https://za.linkedin.com/company/dark-pools"
              target="_blank"
              className="linkedin"
            >
              <i className="bx bxl-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="sidebar">
        {!openBtn ? (
          <button className="nav-btn open-btn" onClick={handleOpenBtn}>
            <i className="fa fa-bars"></i>
          </button>
        ) : (
          <div>
            <div className="nav visible nav-secondary">
              <ul className="ul-list">
                <li className="new-list">
                  <div>
                    <a
                      href="#"
                      onClick={handleOpenListProducts}
                      className="nav-title"
                    >
                      Products
                    </a>
                    {openListProducts && (
                      <ul className="inner-ul-list">
                        <li className="inner-list">
                          <a href="./HyDDEP">HyDDEP</a>
                        </li>
                        <li className="inner-list">
                          <a href="./AIMLow">AIMLow</a>
                        </li>
                        <li className="inner-list">
                          <a href="./NERoML">NERoML</a>
                        </li>
                      </ul>
                    )}
                  </div>
                </li>
                <li className="new-list">
                  <div>
                    <a
                      href="#"
                      onClick={handleOpenListIndustries}
                      className="nav-title"
                    >
                      Industries
                    </a>
                    {openListIndustries && (
                      <ul className="inner-ul-list">
                        <li className="inner-list">
                          <a href="./financial-services">Financial Services</a>
                        </li>
                        <li className="inner-list">
                          <a href="./government">Government</a>
                        </li>
                        <li className="inner-list">
                          <a href="./retail">Retail</a>
                        </li>
                        <li className="inner-list">
                          <a href="./telecom">Telecommunication</a>
                        </li>
                      </ul>
                    )}
                  </div>
                </li>
                <li className="new-list">
                  <a href="./customers" className="nav-title">
                    Customer
                  </a>
                </li>
                <li className="new-list">
                  <a href="./resources" className="nav-title">
                    Resources
                  </a>
                </li>
                <li className="new-list">
                  <a href="./news" className="nav-title">
                    News
                  </a>
                </li>
                <li className="new-list">
                  <a href="./company" className="nav-title">
                    Company
                  </a>
                </li>
              </ul>
            </div>
            <button className="nav-btn close-btn" onClick={handleCloseBtn}>
              <i className="fa fa-times"></i>
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Navigation;
